import { useEffect } from "react";
import { Flex } from "@radix-ui/themes";
import { useBrandContext } from "@/hooks";
import { useSearchParams } from "react-router-dom";

import { ContentErrors } from "@/components";
import { ContentDraftsComponent } from "@/components/content/drafts";
import { useGetBrandsQuery, useGetContentQuery } from "@/redux";

export const ContentDrafts = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const { brand, setBrand } = useBrandContext();

  const { data: brands, error: brandsLoadingError } = useGetBrandsQuery();

  const { data: content, error: contentLoadingError } = useGetContentQuery(
    { brandId: brand?.id || "" },
    {
      skip: !brand?.id,
      pollingInterval: 10000,
    }
  );

  useEffect(() => {
    if (!brands) return;
    if (!searchParams.get("brandId")) return;

    setBrand(brands!.brands.find((b) => b.id === searchParams.get("brandId"))!);
  }, [searchParams, brands, setBrand]);

  const drafts = content?.contents.filter(
    (c) =>
      c.status === "draft" ||
      c.status === "researching" ||
      c.status === "generating" ||
      c.status === "generating_images" ||
      c.status === "images_failed" ||
      c.status === "failed"
  );

  return (
    <>
      <Flex justify="between" pb="3">
        <h1 className="text-lg font-semibold md:text-2xl">Drafts</h1>
      </Flex>

      <ContentErrors
        error={
          (brandsLoadingError && "message" in brandsLoadingError
            ? brandsLoadingError.message
            : brandsLoadingError && "error" in brandsLoadingError
            ? brandsLoadingError.error
            : undefined) ||
          (contentLoadingError && "message" in contentLoadingError
            ? contentLoadingError.message
            : contentLoadingError && "error" in contentLoadingError
            ? contentLoadingError.error
            : undefined)
        }
      />

      <Flex height="100%">
        {(!drafts || drafts?.length === 0) && (
          <div className="flex flex-1 flex-col items-center justify-center gap-1 text-center">
            <h3 className="text-2xl font-bold tracking-tight">
              You have no drafts yet
            </h3>
          </div>
        )}

        {drafts && drafts?.length > 0 && (
          <Flex flexGrow="1" direction="column" width="100%">
            <Flex width="100%">
              <ContentDraftsComponent
                contentItems={drafts.map((item) => ({
                  id: item.id,
                  createdAt: item.createdAt,
                  brand: brand!,
                  brief: item.brief,
                  type: item.type,
                  status: item.status,
                  targetDate: item.targetDate,
                  latestVersion: item.versions[0]?.createdAt,
                  location: `/brands/${brand?.id}/content/${item.id}`,
                  isApproved: item.isApproved,
                }))}
              />
            </Flex>
          </Flex>
        )}
      </Flex>
    </>
  );
};
