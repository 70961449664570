import { TypographyP } from "@/components";
import { Alert } from "@/components/ui/alert";
import { useBrandContext } from "@/hooks";
import { useAuth } from "@/hooks/use-auth";
import { Flex } from "@radix-ui/themes";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RingLoader } from "react-spinners";

export const LinkedinCallback = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const { brand } = useBrandContext();
  const { token } = useAuth();
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleAuthentication = ({
    code,
    state,
  }: {
    code: string;
    state: string;
  }) => {
    axios({
      method: "POST",
      url: `${import.meta.env.VITE_API_URL}/brands/${
        brand?.id
      }/linkedin/authorize`,
      data: {
        code,
        state,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        navigate("/integrations");
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  useEffect(() => {
    const code = searchParams.get("code");
    const state = searchParams.get("state");
    if (!code || !state) return;
    if (!brand) return;
    if (!token) return;

    handleAuthentication({ code, state });
  }, [searchParams, brand, token]);

  return (
    <Flex height="90vh" align="center" justify="center">
      {!error && (
        <Flex direction="column" align="center" justify="center">
          <RingLoader />
          <TypographyP>
            Linking up with Linkedin, this will only take a few seconds...
          </TypographyP>
        </Flex>
      )}
      {error && (
        <Alert variant="destructive">
          Something went wrong, please try again later.
        </Alert>
      )}
    </Flex>
  );
};
