import { DomainAddComponent } from "@/components/domains";
import { useBrandContext } from "@/hooks";
import { useCreateDomainMutation, useGetDomainsQuery } from "@/redux";

export const DomainsAddContainer = (): JSX.Element => {
  const { brand } = useBrandContext();
  const [createDomain] = useCreateDomainMutation();

  const { isLoading } = useGetDomainsQuery(
    { brandId: brand?.id || "" },
    { skip: !brand || !brand.id }
  );

  const handleAddDomain = async ({ domain }: { domain: string }) => {
    if (!brand || isLoading) {
      return;
    }
    await createDomain({ domain, brandId: brand.id });
  };

  return <DomainAddComponent onSubmit={handleAddDomain} disabled={isLoading} />;
};
