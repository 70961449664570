import { TypographyP } from "@/components/typography";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Flex } from "@radix-ui/themes";
import { Check, XIcon } from "lucide-react";

export interface DomainCardProps {
  id: string;
  domain: string;
  isVerified: boolean;
  verifications: {
    type: string;
    name: string;
    value: string;
  }[];
}

export const DomainCard = ({
  id,
  domain,
  isVerified,
  verifications,
}: DomainCardProps): JSX.Element => {
  return (
    <Card key={id}>
      <CardHeader>
        <CardTitle>
          <a href={`https://${domain}`} target="_blank">
            {domain}
          </a>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <Flex align="center" gap="2">
          {isVerified ? (
            <Check className="h-4 w-4" />
          ) : (
            <XIcon className="h-4 w-4" />
          )}
          <TypographyP className="pt-0">
            {isVerified
              ? "Valid Configuration"
              : "Domain is pending verification"}
          </TypographyP>
        </Flex>
      </CardContent>
      {!isVerified && (
        <CardFooter>
          <Flex direction="column" gap="4">
            <TypographyP>
              Set the following records on your DNS provider to continue:
            </TypographyP>

            <Flex direction="row" gap="4">
              <Flex direction="column">
                <TypographyP className="pt-0 text-sm">Type</TypographyP>
                <TypographyP className="pt-0 text-sm">
                  {verifications[0].type}
                </TypographyP>
              </Flex>
              <Flex direction="column">
                <TypographyP className="pt-0 text-sm">Name</TypographyP>
                <TypographyP className="pt-0 text-sm">
                  {verifications[0].name}
                </TypographyP>
              </Flex>
              <Flex direction="column">
                <TypographyP className="pt-0 text-sm">Value</TypographyP>
                <TypographyP className="pt-0 text-sm">
                  {verifications[0].value}
                </TypographyP>
              </Flex>
            </Flex>
          </Flex>
        </CardFooter>
      )}
    </Card>
  );
};
