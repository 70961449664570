import { useEffect, useState } from "react";
import { useApi, UseApiResponse } from "../use-api";
import { TeamMemberDto } from "@/dtos";

export const useTeamMembers = ({
  brandId,
}: {
  brandId?: string;
}): UseApiResponse<{ teamMembers: TeamMemberDto[] }> => {
  const [contentResponse, setContentResponse] = useState<
    UseApiResponse<{ teamMembers: TeamMemberDto[] }>
  >({
    response: null,
    error: null,
    loading: false,
    reload: () => {},
  });

  const { response, error, loading, reload } = useApi<
    unknown,
    unknown,
    { teamMembers: TeamMemberDto[] }
  >({
    endpoint: `brands/${brandId}/team-members`,
    method: "get",
    lazy: !brandId,
  });

  useEffect(() => {
    if (response !== null || error !== null) {
      setContentResponse({ response, error, loading, reload });
    }
  }, [response, error, loading, reload]);

  return contentResponse;
};
