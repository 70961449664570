import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import { Input } from "@/components/ui/input";
import axios from "axios";
import { useAuth } from "@/hooks/use-auth";
import { ScheduleDto } from "@/dtos";
import { useEffect } from "react";
import { TypographyP } from "@/components/typography";

const scheduleFormSchema = z.object({
  brandId: z.string().uuid(),
  name: z.string(),
  goal: z.string(),
});

export interface ScheduleFormProps {
  brand: { name: string; id: string };
  onSuccess: (schedule: ScheduleDto) => void;
  onError: (error: Error) => void;
}

export const ScheduleForm = ({
  brand,
  onSuccess,
  onError,
}: ScheduleFormProps): JSX.Element => {
  const { token } = useAuth();

  const form = useForm<z.infer<typeof scheduleFormSchema>>({
    resolver: zodResolver(scheduleFormSchema),
    defaultValues: {
      brandId: "",
      name: "placeholder",
      goal: "",
    },
  });

  useEffect(() => {
    if (brand) {
      form.setValue("brandId", brand.id);
    }
  }, [brand, form]);

  const onSubmit = async (values: z.infer<typeof scheduleFormSchema>) => {
    axios
      .post(
        `${import.meta.env.VITE_API_URL}/brands/${values.brandId}/schedules`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(({ data }) => {
        onSuccess(data.schedule);
      })
      .catch((error) => {
        onError(error);
      });
  };

  return (
    <Form {...form}>
      <TypographyP className="pt-0">
        Just tell us your objective and we'll generate a list of content ideas
        for you.
      </TypographyP>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-3">
        <FormField
          control={form.control}
          name="goal"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Objective</FormLabel>
              <FormControl>
                <Input
                  placeholder="e.g educate people about content marketing"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button type="submit">Submit</Button>
      </form>
    </Form>
  );
};
