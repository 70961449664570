/**
 * v0 by Vercel.
 * @see https://v0.dev/t/nCWsb4NVr6m
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import { useState, useEffect, ReactNode } from "react";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select";
import { Card, CardContent } from "@/components/ui/card";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";

interface Event {
  id: string;
  content: ReactNode;
  date: Date;
  color?: string;
  link?: string;
}

interface ScheduleProps {
  events: Event[];
}

const getDaysInMonth = (year: number, month: number) =>
  new Date(year, month + 1, 0).getDate();

const getFirstDayOfMonth = (year: number, month: number) =>
  new Date(year, month, 1).getDay();

export default function Schedule({ events = [] }: ScheduleProps) {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [calendarDays, setCalendarDays] = useState<(number | null)[]>([]);
  const [viewType, setViewType] = useState<"month" | "week">("month");
  const navigate = useNavigate();

  useEffect(() => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const daysInMonth = getDaysInMonth(year, month);
    const firstDayOfMonth = getFirstDayOfMonth(year, month);

    if (viewType === "month") {
      const days = Array(35).fill(null);
      for (let i = 0; i < daysInMonth; i++) {
        days[i + firstDayOfMonth] = i + 1;
      }
      setCalendarDays(days);
    } else {
      // Week view
      const currentDay = currentDate.getDay();
      const weekStart = new Date(currentDate);
      weekStart.setDate(currentDate.getDate() - currentDay);

      const days = Array(7)
        .fill(null)
        .map((_, index) => {
          const day = new Date(weekStart);
          day.setDate(weekStart.getDate() + index);
          return day.getDate();
        });
      setCalendarDays(days);
    }
  }, [currentDate, viewType]);

  const handlePrev = () => {
    setCurrentDate((prev) => {
      if (viewType === "month") {
        return new Date(prev.getFullYear(), prev.getMonth() - 1, 1);
      } else {
        const newDate = new Date(prev);
        newDate.setDate(prev.getDate() - 7);
        return newDate;
      }
    });
  };

  const handleNext = () => {
    setCurrentDate((prev) => {
      if (viewType === "month") {
        return new Date(prev.getFullYear(), prev.getMonth() + 1, 1);
      } else {
        const newDate = new Date(prev);
        newDate.setDate(prev.getDate() + 7);
        return newDate;
      }
    });
  };

  const handleViewChange = (value: string) => {
    setViewType(value as "month" | "week");
  };

  const renderEvents = (day: number | null) => {
    if (day === null) return null;

    const dayEvents = events.filter((event) => {
      const eventDate = new Date(event.date);
      return (
        eventDate.getDate() === day &&
        eventDate.getMonth() === currentDate.getMonth() &&
        eventDate.getFullYear() === currentDate.getFullYear()
      );
    });

    return dayEvents.map((event) => (
      <div
        key={event.id}
        className={`${event.link ? "cursor-pointer" : ""}`}
        onClick={() => event.link && navigate(event.link)}
      >
        <div className="hidden sm:block">
          <Card className="bg-primary text-primary-foreground mb-1">
            <CardContent className="p-1 sm:p-2">
              <div className="text-[10px] sm:text-xs font-medium">
                {event.content}
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="sm:hidden w-2 h-2 rounded-full bg-primary mb-1"></div>
      </div>
    ));
  };

  return (
    <div className="w-full mx-auto overflow-y-auto">
      <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-4 space-y-2 sm:space-y-0">
        <div className="text-xl sm:text-2xl font-semibold">
          {viewType === "month"
            ? currentDate.toLocaleString("default", {
                month: "long",
                year: "numeric",
              })
            : `Week of ${currentDate.toLocaleString("default", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}`}
        </div>
        <div className="flex items-center gap-2">
          <Button variant="outline" size="sm" onClick={handlePrev}>
            <ChevronLeftIcon className="w-4 h-4" />
          </Button>
          <Button variant="outline" size="sm" onClick={handleNext}>
            <ChevronRightIcon className="w-4 h-4" />
          </Button>
          <Select onValueChange={handleViewChange} defaultValue={viewType}>
            <SelectTrigger className="h-8 w-24">
              <SelectValue placeholder="View" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="month">Month</SelectItem>
              <SelectItem value="week">Week</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className="grid grid-cols-7 gap-1 sm:gap-4">
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
          <div
            key={day}
            className="text-center text-xs sm:text-sm font-medium text-muted-foreground"
          >
            {day}
          </div>
        ))}
        {calendarDays.map((day, index) => (
          <div
            key={index}
            className="relative rounded-md bg-muted/20 p-1 sm:p-2 border border-muted"
          >
            {day !== null && (
              <div className="text-xs sm:text-sm font-medium">{day}</div>
            )}
            <div className="mt-1 sm:mt-2 space-y-1 sm:space-y-2 h-[20px] sm:h-[25px] md:h-[30px] lg:h-[40px] xl:h-[65px] 2xl:h-[100px] overflow-y-auto">
              {renderEvents(day)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
