import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { NotificationDto } from "@/dtos";
import React, { createContext, useEffect, useCallback, useState } from "react";
import axios from "axios";
import { useAuth } from "@/hooks/use-auth";
import { Flex } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";
import { useGetNotificationsQuery } from "@/redux";

export interface NotificationsContextType {
  notifications?: NotificationDto[];
  addNotification: ({
    message,
    url,
  }: {
    message: string;
    url?: string;
  }) => void;
  addError: ({ message }: { message: string }) => void;
}

export const NotificationsContext = createContext<
  NotificationsContextType | undefined
>({
  notifications: [],
  addNotification: () => {},
  addError: () => {},
});

export interface NotificationsProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

export const NotificationsProvider = ({
  children,
}: NotificationsProviderProps) => {
  const { token, user } = useAuth();
  const { data: notificationsData, refetch: reload } = useGetNotificationsQuery(
    undefined,
    {
      skip: !token || !user,
    }
  );
  const notifications = notificationsData?.notifications;
  const [inTray, setInTray] = useState<string[]>([]);
  const navigate = useNavigate();

  const displayNotification = useCallback(
    async ({
      message,
      notificationId,
      relativeUrl,
    }: {
      message: string;
      notificationId?: string;
      relativeUrl?: string;
    }) => {
      const toastId = toast.success(
        <Flex align="center" justify="between">
          {message}
          {relativeUrl && (
            <Button
              variant="ghost"
              onClick={async () => {
                if (notificationId) {
                  await axios.post(
                    `${
                      import.meta.env.VITE_API_URL
                    }/notifications/${notificationId}/read`,
                    {},
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  );
                }

                navigate(relativeUrl);

                toast.dismiss(toastId);
              }}
            >
              View
            </Button>
          )}
          <Button
            variant="ghost"
            onClick={async () => {
              if (notificationId) {
                await axios.post(
                  `${
                    import.meta.env.VITE_API_URL
                  }/notifications/${notificationId}/read`,
                  {},
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                );
              }
              toast.dismiss(toastId);
            }}
          >
            Dismiss
          </Button>
        </Flex>,
        {
          duration: Infinity,
        }
      );
    },
    [token]
  );

  const displayError = useCallback(({ message }: { message: string }) => {
    const toastId = toast.error(
      <Flex align="center" justify="between">
        {message}
        <Button
          variant="ghost"
          onClick={() => {
            toast.dismiss(toastId);
          }}
        >
          Dismiss
        </Button>
      </Flex>,
      {
        duration: Infinity,
      }
    );
  }, []);

  useEffect(() => {
    const pollInterval = setInterval(() => {
      reload();
    }, 30000);

    return () => clearInterval(pollInterval);
  }, [reload]);

  useEffect(() => {
    if (!notifications) return;

    const unreadNotifications = notifications.filter(
      (notification) => !notification.isRead
    );

    unreadNotifications.forEach((notification) => {
      if (inTray.includes(notification.id)) return;

      displayNotification({
        message: notification.message,
        relativeUrl: notification.relativeUrl,
        notificationId: notification.id,
      });

      setInTray([...inTray, notification.id]);
    });
  }, [notifications]);

  const addNotification = ({
    message,
    relativeUrl,
  }: {
    message: string;
    relativeUrl?: string;
  }) => {
    displayNotification({ message, relativeUrl });
  };

  const addError = ({ message }: { message: string }) => {
    displayError({ message });
  };

  return (
    <NotificationsContext.Provider
      value={{ notifications, addNotification, addError }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
