import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { Flex } from "@radix-ui/themes";
import { useForm } from "react-hook-form";
import { RingLoader } from "react-spinners";
import { z } from "zod";

export interface DomainAddComponentProps {
  onSubmit: (data: z.infer<typeof FormSchema>) => void;
  disabled?: boolean;
}

const FormSchema = z.object({
  domain: z
    .string()
    .min(2, {
      message: "Domain must be at least 2 characters.",
    })
    .max(253, {
      message: "Domain must be at most 253 characters.",
    })
    .regex(
      /^[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z]{2,})+$/,
      {
        message: "Please enter a valid domain name.",
      }
    ),
});

export const DomainAddComponent = ({
  onSubmit,
  disabled,
}: DomainAddComponentProps): JSX.Element => {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      domain: "",
    },
  });

  return (
    <Flex gap="2" width="full">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="domain"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input placeholder="mydomain.com" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </form>
      </Form>
      <Button
        disabled={disabled || !form.formState.isValid}
        onClick={() => form.handleSubmit(onSubmit)()}
      >
        {disabled ? <RingLoader size={25} color="white" /> : "Add"}
      </Button>
    </Flex>
  );
};
