import {
  NotificationsContext,
  NotificationsContextType,
} from "@/contexts/notifications";
import { useContext } from "react";

export const useNotificationsContext = (): NotificationsContextType => {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error("NotificationsContext is undefined");
  }
  return context;
};
