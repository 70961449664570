import { useEffect, useState } from "react";
import { useApi, UseApiResponse } from "../use-api";
import { ApiKeyDTO } from "@/dtos/api-keys";

export const useApiKeys = ({
  brandId,
}: {
  brandId?: string;
}): UseApiResponse<{ apiKeys: ApiKeyDTO[] }> => {
  const [apiKeysResponse, setApiKeysResponse] = useState<
    UseApiResponse<{ apiKeys: ApiKeyDTO[] }>
  >({
    response: null,
    error: null,
    loading: false,
    reload: () => {},
  });

  const { response, error, loading, reload } = useApi<
    unknown,
    unknown,
    { apiKeys: ApiKeyDTO[] }
  >({
    endpoint: `brands/${brandId}/api-keys`,
    method: "get",
    lazy: !brandId,
  });

  useEffect(() => {
    if (response !== null || error !== null) {
      setApiKeysResponse({ response, error, loading, reload });
    }
  }, [response, error, loading, reload]);

  return apiKeysResponse;
};
