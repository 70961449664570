import { useEffect, useState } from "react";
import { useApi, UseApiResponse } from "../use-api";
import { LinkedinPageDto } from "@/dtos";

export const useLinkedinPages = ({
  brandId,
}: {
  brandId?: string;
}): UseApiResponse<{ pages: LinkedinPageDto[] }> => {
  const [linkedinPagesResponse, setLinkedinPagesResponse] = useState<
    UseApiResponse<{ pages: LinkedinPageDto[] }>
  >({
    response: null,
    error: null,
    loading: false,
    reload: () => {},
  });

  const { response, error, loading, reload } = useApi<
    unknown,
    unknown,
    { pages: LinkedinPageDto[] }
  >({
    endpoint: `brands/${brandId}/linkedin/pages`,
    method: "get",
    lazy: !brandId,
  });

  useEffect(() => {
    if (response !== null || error !== null) {
      setLinkedinPagesResponse({ response, error, loading, reload });
    }
  }, [response, error, loading, reload]);

  return linkedinPagesResponse;
};
