export const TypographyH3 = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLHeadingElement>) => {
  return (
    <h3
      className={`scroll-m-20 text-2xl font-semibold tracking-tight ${
        className || ""
      }`}
      {...props}
    />
  );
};
