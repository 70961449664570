import React, { createContext, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { ContentVersionForm } from "../forms";
import { ContentDto } from "@/dtos";
import { useBrandContext } from "@/hooks";
import { Flex } from "@radix-ui/themes";
import { DeleteContainer } from "@/containers";

export const ContentTableDialogContext = createContext<{
  openDialog: () => void;
  closeDialog: () => void;
  open: boolean;
  content: ContentDto | null;
  setContent: (content: ContentDto) => void;
  setAction: (action: "new_version" | "delete") => void;
  error: string | null;
}>({
  openDialog: () => {},
  closeDialog: () => {},
  open: false,
  content: null,
  setContent: () => {},
  setAction: () => {},
  error: null,
});

export const ContentTableDialogProvider = ({
  children,
  onSuccess,
}: {
  children: React.ReactNode;
  onSuccess: () => void;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [content, setContent] = useState<ContentDto | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [action, setAction] = useState<"new_version" | "delete">("new_version");

  const { brand } = useBrandContext();

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  return (
    <ContentTableDialogContext.Provider
      value={{
        openDialog,
        closeDialog,
        open,
        content,
        setContent,
        setAction,
        error,
      }}
    >
      <Flex height="100%" direction="column">
        {children}
      </Flex>

      <Dialog
        open={open}
        onOpenChange={(isOpen) => (isOpen ? openDialog() : closeDialog())}
      >
        <DialogContent>
          {action === "new_version" && (
            <>
              <DialogHeader>
                <DialogTitle>New Version</DialogTitle>
              </DialogHeader>
              <DialogDescription>{content?.brief}</DialogDescription>
              <ContentVersionForm
                brand={brand!}
                content={content!}
                onSuccess={() => {
                  onSuccess();
                  closeDialog();
                }}
                onError={(error) => {
                  if (error instanceof Error) {
                    setError(error.message);
                  } else {
                    setError("Something went wrong. Please try again later.");
                  }
                  closeDialog();
                }}
              />
            </>
          )}

          {action === "delete" && (
            <DeleteContainer
              brandId={brand?.id}
              contentBrief={content?.brief}
              contentId={content?.id}
              onSuccess={() => {
                onSuccess();
                closeDialog();
              }}
              onCancel={closeDialog}
              onError={(error) => {
                if (error instanceof Error) {
                  setError(error.message);
                } else {
                  setError("Something went wrong. Please try again later.");
                }
                closeDialog();
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    </ContentTableDialogContext.Provider>
  );
};
