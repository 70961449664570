import { TypographyP } from "@/components/typography";
import { Toggle } from "@/components/ui/toggle";
import { Box, Flex } from "@radix-ui/themes";
import { Editor } from "@tiptap/react";
import { formatDistanceToNow } from "date-fns";
import {
  BoldIcon,
  Cable,
  Heading1Icon,
  Heading2Icon,
  Heading3Icon,
  Heading4Icon,
  ItalicIcon,
} from "lucide-react";
import { RingLoader } from "react-spinners";
import AddImageModal from "./add-image-modal.component";

export interface EditorMenuProps {
  connected: boolean;
  saving: boolean;
  lastSaved: string | null;
  editor: Editor;
  enableImages?: boolean;
  compact?: boolean;
}

export const EditorMenu = ({
  editor,
  connected,
  saving,
  lastSaved,
  compact,
  enableImages = true,
}: EditorMenuProps) => {
  return (
    <Flex className="border-b" px="2" py="2" gap="2" justify={"center"}>
      <Toggle
        pressed={editor.isActive("bold")}
        onPressedChange={() => editor.chain().focus().toggleBold().run()}
      >
        <BoldIcon size={16} />
      </Toggle>
      <Toggle
        pressed={editor.isActive("italic")}
        onPressedChange={() => editor.chain().focus().toggleItalic().run()}
      >
        <ItalicIcon size={16} />
      </Toggle>
      <Toggle
        pressed={editor.isActive("heading", { level: 1 })}
        onPressedChange={() =>
          editor.chain().focus().toggleHeading({ level: 1 }).run()
        }
      >
        <Heading1Icon size={16} />
      </Toggle>
      <Toggle
        pressed={editor.isActive("heading", { level: 2 })}
        onPressedChange={() =>
          editor.chain().focus().toggleHeading({ level: 2 }).run()
        }
      >
        <Heading2Icon size={16} />
      </Toggle>

      <Toggle
        pressed={editor.isActive("heading", { level: 3 })}
        onPressedChange={() =>
          editor.chain().focus().toggleHeading({ level: 3 }).run()
        }
      >
        <Heading3Icon size={16} />
      </Toggle>
      <Toggle
        pressed={editor.isActive("heading", { level: 4 })}
        onPressedChange={() =>
          editor.chain().focus().toggleHeading({ level: 4 }).run()
        }
      >
        <Heading4Icon size={16} />
      </Toggle>

      {enableImages && (
        <Flex height={"full"}>
          <AddImageModal editor={editor} />
        </Flex>
      )}

      {!compact && (
        <Flex align="center" justify="end" width="100%">
          {lastSaved && (
            <TypographyP>
              Last saved: {formatDistanceToNow(lastSaved, { addSuffix: true })}
            </TypographyP>
          )}
          <Box px="2">
            {saving && <RingLoader size={16} />}
            {!saving && <Cable size={16} color={connected ? "green" : "red"} />}
          </Box>
        </Flex>
      )}
    </Flex>
  );
};
