import { useAuth } from "@/hooks/use-auth";
import { decodeJwt } from "jose";
import React, { createContext, useState, ReactNode, useEffect } from "react";

export interface BrandContextType {
  brand: { id: string; name: string; subscriptionStatus?: string } | null;
  setBrand: React.Dispatch<
    React.SetStateAction<{
      id: string;
      name: string;
      subscriptionStatus?: string;
    } | null>
  >;
}

export const BrandContext = createContext<BrandContextType | undefined>(
  undefined
);

export interface BrandProviderProps {
  children: ReactNode;
}

export const BrandProvider = ({ children }: BrandProviderProps) => {
  const { token } = useAuth();

  const [brand, setBrand] = useState<{
    id: string;
    name: string;
    subscriptionStatus?: string;
  } | null>(() => {
    if (!token) return null;

    const decodedToken = decodeJwt(token);
    if (!decodedToken.sub) return null;

    const brandMap = JSON.parse(localStorage.getItem("brandMap") || "{}");
    return brandMap[decodedToken.sub] || null;
  });

  const handleSetBrand: BrandContextType["setBrand"] = (brandOrNull) => {
    if (!token) return;
    const decodedToken = decodeJwt(token);
    if (!decodedToken.sub) return;

    setBrand(brandOrNull);
    if (decodedToken.sub) {
      const brandMap = JSON.parse(localStorage.getItem("brandMap") || "{}");
      if (brandOrNull) {
        brandMap[decodedToken.sub] = brandOrNull;
      } else {
        delete brandMap[decodedToken.sub];
      }
      localStorage.setItem("brandMap", JSON.stringify(brandMap));
    }
  };

  useEffect(() => {
    if (!token || !brand) return;

    const decodedToken = decodeJwt(token);
    if (!decodedToken.sub) return;

    const brandMap = JSON.parse(localStorage.getItem("brandMap") || "{}");
    brandMap[decodedToken.sub] = brand;

    localStorage.setItem("brandMap", JSON.stringify(brandMap));
  }, [brand, token]);

  useEffect(() => {
    if (!token) return;
    const decodedToken = decodeJwt(token);
    if (!decodedToken.sub) return;

    const brandMap = JSON.parse(localStorage.getItem("brandMap") || "{}");
    setBrand(brandMap[decodedToken.sub] || null);
  }, [token]);

  return (
    <BrandContext.Provider value={{ brand, setBrand: handleSetBrand }}>
      {children}
    </BrandContext.Provider>
  );
};
