import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";
import { logout, hasTokenExpired } from "./auth.slice"; // Import the logout action and hasTokenExpired function
import { Middleware, Dispatch } from "@reduxjs/toolkit";

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_URL}`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});

export const tokenExpirationMiddleware: Middleware =
  (store) => (next) => (action) => {
    const result = next(action);
    const state = store.getState();
    const token = state.auth.token;

    if (token && hasTokenExpired(token)) {
      store.dispatch(logout() as unknown as ReturnType<Dispatch>);
    }

    return result;
  };
