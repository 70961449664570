import { BrandDTO } from "@/dtos";
import { baseApi } from "./base-api.slice";
import { Subscription } from "@paddle/paddle-node-sdk";

const baseApiWithTags = baseApi.enhanceEndpoints({ addTagTypes: ["Brand"] });

const brandsApi = baseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getBrands: builder.query<{ brands: BrandDTO[] }, void>({
      query: () => "/brands",
      providesTags: ["Brand"],
    }),
    getBrandById: builder.query<{ brand: BrandDTO }, { id: string }>({
      query: ({ id }) => `/brands/${id}`,
      providesTags: ["Brand"],
    }),
    getBrandSubscriptionByBrandId: builder.query<
      { subscription: Partial<Subscription> },
      { brandId: string }
    >({
      query: ({ brandId }) => `/brands/${brandId}/subscription`,
      providesTags: ["Brand"],
    }),
  }),
});

export const {
  useGetBrandsQuery,
  useGetBrandByIdQuery,
  useGetBrandSubscriptionByBrandIdQuery,
} = brandsApi;

export const { invalidateTags: invalidateBrandsTags } = brandsApi.util;
