import { TypographyH1, TypographyP } from "@/components";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "@/components/ui/input-otp";
import { useAuth } from "@/hooks/use-auth";
import { useGoPasswordlessContext } from "@gopasswordless/sdk";
import { zodResolver } from "@hookform/resolvers/zod";
import { Flex } from "@radix-ui/themes";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

const OTPFormSchema = z.object({
  otp: z.string().length(6),
});

export const OtpContainer = (): JSX.Element => {
  const form = useForm<z.infer<typeof OTPFormSchema>>({
    resolver: zodResolver(OTPFormSchema),
    defaultValues: {
      otp: "",
    },
  });

  const { handleError } = useAuth();

  const { verify, resendCode } = useGoPasswordlessContext();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const onOTPSubmit = async (
    data: z.infer<typeof OTPFormSchema>
  ): Promise<void> => {
    setIsLoading(true);

    try {
      await verify({ code: data.otp });
      navigate("/");
    } catch (error) {
      if (error instanceof Error) {
        handleError(error.message);
      } else {
        handleError("Something went wrong, please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex
      direction="column"
      align="center"
      justify="between"
      width="100%"
      height="100%"
    >
      <Flex />

      <Flex
        direction="column"
        gap="6"
        align="center"
        justify="center"
        width={{ initial: "75%", sm: "50%" }}
      >
        <TypographyH1 className="text-center">
          Verify your email address
        </TypographyH1>

        <TypographyP className="pt-0 text-center">
          Please enter the 6-digit verification code we've sent to your email
          address. This code helps us confirm your identity and secure your
          account.
        </TypographyP>

        <Flex
          direction="column"
          gap="4"
          align="center"
          justify="center"
          width="100%"
        >
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onOTPSubmit)}
              style={{ width: "100%" }}
            >
              <Flex direction="column" gap="4" width="100%" align="center">
                <FormField
                  control={form.control}
                  name="otp"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <InputOTP maxLength={6} {...field}>
                          <InputOTPGroup>
                            <InputOTPSlot index={0} />
                          </InputOTPGroup>

                          <InputOTPSeparator />

                          <InputOTPGroup>
                            <InputOTPSlot index={1} />
                          </InputOTPGroup>

                          <InputOTPSeparator />

                          <InputOTPGroup>
                            <InputOTPSlot index={2} />
                          </InputOTPGroup>

                          <InputOTPSeparator />

                          <InputOTPGroup>
                            <InputOTPSlot index={3} />
                          </InputOTPGroup>

                          <InputOTPSeparator />

                          <InputOTPGroup>
                            <InputOTPSlot index={4} />
                          </InputOTPGroup>

                          <InputOTPSeparator />

                          <InputOTPGroup>
                            <InputOTPSlot index={5} />
                          </InputOTPGroup>
                        </InputOTP>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <Button
                  variant="default"
                  style={{ width: "75%" }}
                  disabled={isLoading}
                  type="submit"
                >
                  Continue
                </Button>
              </Flex>
            </form>
          </Form>
        </Flex>

        <Flex align="center" justify="center" gap="2">
          <TypographyP className="pt-0">Didn't receive your code?</TypographyP>
          <TypographyP onClick={resendCode} className="pt-0 cursor-pointer">
            Resend code
          </TypographyP>
        </Flex>
      </Flex>

      <Flex />
    </Flex>
  );
};
