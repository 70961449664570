import { TypographyP } from "@/components";
import { DomainsAddContainer, DomainsListContainer } from "@/containers";
import { Flex } from "@radix-ui/themes";

export const DomainsPage = (): JSX.Element => {
  return (
    <Flex direction="column" gap="4">
      <Flex justify="between">
        <h1 className="text-lg font-semibold md:text-2xl">Domains</h1>
      </Flex>

      <Flex>
        <TypographyP>
          These domains are assigned to your content. Content of type "Blog
          Post" that has been published will be accessible over these domains.
        </TypographyP>
      </Flex>

      <Flex width="full">
        <DomainsAddContainer />
      </Flex>

      <Flex direction="column">
        <DomainsListContainer />
      </Flex>
    </Flex>
  );
};
