import {
  ContentGeneratingComponent,
  ContentIdeaComponent,
  ContentResearchingComponent,
  ContentVersionForm,
  TypographyH1,
  TypographyH4,
  TypographyP,
} from "@/components";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { useBrandContext, useNotificationsContext } from "@/hooks";
import { Flex } from "@radix-ui/themes";
import { RocketIcon } from "lucide-react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { formatRelative } from "date-fns";
import { BlogThumbnail } from "@/components/blog-thumbnail";
import { AxiosError } from "axios";
import { ApproveContainer } from "@/containers/approve";
import { Separator } from "@/components/ui/separator";
import { useGetContentByIdQuery } from "@/redux";
import { EditorContainer } from "@/containers";

export const ContentDetails = (): JSX.Element => {
  const { brandId, contentId } = useParams();
  const { addNotification } = useNotificationsContext();

  const { setBrand } = useBrandContext();

  const { data, isFetching, refetch } = useGetContentByIdQuery(
    { brandId: brandId!, contentId: contentId! },
    {
      skip: !brandId || !contentId,
    }
  );

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (
      data?.content?.status === "researching" ||
      data?.content?.status === "generating" ||
      data?.content?.status === "generating_images"
    ) {
      interval = setInterval(() => {
        refetch();
      }, 5000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [data?.content?.status, refetch]);

  useEffect(() => {
    setBrand({ id: brandId!, name: "" });
  }, [brandId, setBrand]);

  const onNewVersionError = (error: Error) => {
    if (error instanceof AxiosError) {
      addNotification({
        message: error.response?.data.message,
      });
    } else {
      addNotification({
        message: error.message,
      });
    }
  };

  const isIdea = data?.content.status === "idea";
  const isResearching = data?.content.status === "researching";
  const isGenerating = data?.content.status === "generating";
  const isGeneratingImages = data?.content.status === "generating_images";
  const isDraft = data?.content.status === "draft";
  const isFailedResearch = data?.content.status === "research_failed";
  const isFailedImages = data?.content.status === "images_failed";
  const isFailed = data?.content.status === "failed";
  const isFinal = data?.content.status === "final";

  return (
    <Flex direction="column" overflowY="auto">
      <Flex justify="between" pb="5" gap="3">
        <TypographyH4>{data?.content.brief}</TypographyH4>
        <TypographyH4>
          Last updated:{" "}
          {data?.content.versions[0]?.createdAt
            ? formatRelative(data?.content.versions[0].createdAt, new Date())
            : "No versions yet"}
        </TypographyH4>
      </Flex>

      {isIdea && (
        <ContentIdeaComponent
          brandId={brandId || ""}
          content={data?.content}
          onNewVersionSuccess={refetch}
          onNewVersionError={onNewVersionError}
        />
      )}

      {isResearching && <ContentResearchingComponent />}

      {isGenerating && <ContentGeneratingComponent />}

      {(!isFetching || isGeneratingImages) &&
        data?.content?.versions &&
        data?.content?.versions.length > 0 &&
        (isGeneratingImages ||
          isFailedImages ||
          isDraft ||
          isFailed ||
          isFinal) && (
          <>
            {isGeneratingImages && (
              <Flex pt="2" pb="7">
                <Alert>
                  <RocketIcon className="h-4 w-4" />
                  <AlertTitle>Heads up!</AlertTitle>
                  <AlertDescription>
                    We're still generating the images for this content! Please
                    check back in a few minutes.
                  </AlertDescription>
                </Alert>
              </Flex>
            )}

            {isFailedResearch && (
              <Flex pt="2" pb="7" direction="column" gap="4">
                <Alert variant="destructive">
                  <AlertTitle>Failed!</AlertTitle>
                  <AlertDescription>
                    The research step failed, please try again.
                  </AlertDescription>
                </Alert>
              </Flex>
            )}

            {isFailed && (
              <Flex pt="2" pb="7" direction="column" gap="4">
                <Alert variant="destructive">
                  <AlertTitle>Failed!</AlertTitle>
                  <AlertDescription>
                    The text content failed to generate, please try again.
                  </AlertDescription>
                </Alert>
              </Flex>
            )}

            {isFailedImages && (
              <Flex pt="2" pb="7" direction="column" gap="4">
                <Alert variant="destructive">
                  <AlertTitle>Failed!</AlertTitle>
                  <AlertDescription>
                    The text content was generated successfully, but the images
                    failed to generate. Please try again.
                  </AlertDescription>
                </Alert>
              </Flex>
            )}

            <Flex justify="between">
              <TypographyH1>{data?.content?.versions[0]?.title}</TypographyH1>
            </Flex>

            <Flex width={"100%"} pt="6">
              <Flex direction="column" width={"66%"} pb="4">
                <Flex>
                  <EditorContainer
                    contentId={contentId || ""}
                    editable={isDraft}
                    enableImages={data?.content.type === "blog_post"}
                    key={`${contentId}-${data?.content.status}`}
                  />
                </Flex>
              </Flex>

              <Flex direction="column" width={"34%"} pl="4" gap="24px">
                {data?.content.type === "blog_post" && (
                  <BlogThumbnail
                    thumbnail={data?.content?.versions[0]?.thumbnail || ""}
                    generating={isGeneratingImages}
                    failed={isFailedImages}
                  />
                )}

                <Flex direction="column" className="border rounded-lg" p="4">
                  {data?.content.type === "blog_post" && (
                    <Flex direction="column" width="100%" pb="6" gap="2">
                      <TypographyH4>Meta Description</TypographyH4>
                      <TypographyP className="pt-0">
                        {data?.content?.versions[0]?.meta}
                      </TypographyP>
                    </Flex>
                  )}

                  {!isFinal && (
                    <Flex
                      direction="column"
                      gap="2"
                      className={
                        data?.content.type === "blog_post" ? "border-t" : ""
                      }
                      pt={data?.content.type === "blog_post" ? "5" : "0"}
                      pb="6"
                    >
                      <TypographyH4>Generate a New Version</TypographyH4>
                      <TypographyP className="pt-0">
                        Provide Marketer AI with feedback and ask it to generate
                        a new version.
                      </TypographyP>
                      {data?.content && (
                        <ContentVersionForm
                          brand={{ id: brandId!, name: "" }}
                          content={data?.content}
                          onSuccess={refetch}
                          onError={() => {}}
                          displayLabel={false}
                          buttonText="Generate new version"
                          buttonFullWidth={true}
                          disabled={
                            data?.content?.status !== "draft" &&
                            data?.content?.status !== "images_failed"
                          }
                        />
                      )}
                    </Flex>
                  )}

                  {!isFinal && (
                    <Flex direction="column" gap="12px">
                      <Separator />
                      <ApproveContainer
                        brandId={brandId}
                        contentId={contentId}
                        contentType={data?.content.type}
                        contentStatus={data?.content.status}
                        isApproved={data?.content.isApproved}
                        publishDate={data?.content.targetDate}
                      />
                    </Flex>
                  )}

                  {isFinal && (
                    <Flex direction="column" gap="2">
                      <ApproveContainer
                        brandId={brandId}
                        contentId={contentId}
                        contentType={data?.content.type}
                        contentStatus={data?.content.status}
                        isApproved={data?.content.isApproved}
                        publishDate={data?.content.targetDate}
                      />
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </>
        )}

      {!isFetching && isFailed && data?.content.versions.length === 0 && (
        <Flex pt="2" pb="7" direction="column" gap="4">
          <Alert variant="destructive">
            <AlertTitle>Failed!</AlertTitle>
            <AlertDescription>
              This content failed to generate. Please try again.
            </AlertDescription>
          </Alert>

          <Flex width="30%">
            <ContentVersionForm
              firstDraft={true}
              brand={{ id: brandId!, name: "" }}
              content={data?.content}
              onSuccess={refetch}
              onError={() => {}}
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
