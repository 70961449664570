import { ContentDto } from "@/dtos";
import { baseApi } from "./base-api.slice";

const baseApiWithTags = baseApi.enhanceEndpoints({ addTagTypes: ["Content"] });

const contentApi = baseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getContent: builder.query<{ contents: ContentDto[] }, { brandId: string }>({
      query: ({ brandId }) => `/brands/${brandId}/content`,
      providesTags: ["Content"],
    }),
    getContentById: builder.query<
      { content: ContentDto },
      { brandId: string; contentId: string }
    >({
      query: ({ brandId, contentId }) =>
        `/brands/${brandId}/content/${contentId}`,
      providesTags: ["Content"],
    }),
    createContent: builder.mutation<
      { content: ContentDto },
      { brandId: string; brief: string; type: string }
    >({
      query: ({ brandId, brief, type }) => ({
        url: `/brands/${brandId}/content`,
        method: "POST",
        body: { brief, type },
      }),
      invalidatesTags: ["Content"],
    }),
    createContentVersion: builder.mutation<
      void,
      { brandId: string; contentId: string; feedback?: string | null }
    >({
      query: ({ brandId, contentId, feedback }) => ({
        url: `/brands/${brandId}/content/${contentId}/versions`,
        method: "POST",
        body: { feedback },
      }),
      invalidatesTags: ["Content"],
    }),
    approveContent: builder.mutation<
      void,
      {
        brandId: string;
        contentId: string;
        publishDate: Date;
        linkedinOrganisationId?: string;
      }
    >({
      query: ({ brandId, contentId, publishDate, linkedinOrganisationId }) => ({
        url: `/brands/${brandId}/content/${contentId}/approve`,
        method: "POST",
        body: { publishDate, linkedinOrganisationId },
      }),
      invalidatesTags: ["Content"],
    }),
    cancelApproval: builder.mutation<
      void,
      { brandId: string; contentId: string }
    >({
      query: ({ brandId, contentId }) => ({
        url: `/brands/${brandId}/content/${contentId}/approve`,
        method: "DELETE",
      }),
      invalidatesTags: ["Content"],
    }),
  }),
});

export const {
  useGetContentQuery,
  useGetContentByIdQuery,
  useApproveContentMutation,
  useCancelApprovalMutation,
  useCreateContentVersionMutation,
  useCreateContentMutation,
} = contentApi;

export const { invalidateTags: invalidateContentTags } = contentApi.util;
