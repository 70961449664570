import { Flex } from "@radix-ui/themes";
import { Link, Outlet } from "react-router-dom";

export const AuthLayout = (): JSX.Element => {
  return (
    <Flex justify="center" align="center" className="h-[100vh]">
      <Flex
        display={{ initial: "none", sm: "flex" }}
        width={{ initial: "0%", sm: "30%" }}
        height="100%"
      >
        <Flex
          style={{ background: "black" }}
          height="100%"
          width="100%"
          justify="between"
          align="start"
          p="24px"
          direction="column"
        >
          <Flex>
            <img src="/logolight.svg" alt="Marketer AI" className="w-[200px]" />
          </Flex>

          <Flex direction="column" gap="36px" width="100%"></Flex>

          <Flex style={{ color: "white" }} justify="between" width="100%">
            <Link to="https://getmarketerai.com">Back to home</Link>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        width={{ initial: "100%", sm: "70%" }}
        height="100%"
        p={{ initial: "0px", sm: "12px" }}
        pl="0px"
        justify={"center"}
      >
        <Flex
          width="100%"
          height="100%"
          justify="center"
          align="center"
          direction="column"
          gap="24px"
          p={{ initial: "6px", sm: "24px" }}
        >
          <Flex justify="center" align="center" flexGrow="1" width="100%">
            <Outlet />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
