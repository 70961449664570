import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { Input } from "@/components/ui/input";
import { ContentDto } from "@/dtos";
import { useEffect } from "react";
import { useCreateContentMutation } from "@/redux";
import { RingLoader } from "react-spinners";
import { useNotificationsContext } from "@/hooks";

const contentFormSchema = z.object({
  brandId: z.string().uuid(),
  brief: z.string().min(1).max(500),
  type: z.enum(["blog_post", "x_post", "linkedin_post"]).default("blog_post"),
});

export interface ContentFormProps {
  brand: { name: string; id: string };
  onSuccess: (content: ContentDto) => void;
}

export const ContentForm = ({
  brand,
  onSuccess,
}: ContentFormProps): JSX.Element => {
  const { addError } = useNotificationsContext();

  const [createContent, { isLoading, isError, error }] =
    useCreateContentMutation();

  const form = useForm<z.infer<typeof contentFormSchema>>({
    resolver: zodResolver(contentFormSchema),
    defaultValues: {
      brandId: "",
      brief: "",
      type: "blog_post",
    },
  });

  useEffect(() => {
    if (brand) {
      form.setValue("brandId", brand.id);
    }
  }, [brand, form]);

  useEffect(() => {
    if (isError) {
      addError({
        message: error instanceof Error ? error.message : "Unknown error",
      });
    }
  }, [isError, addError, error]);

  const onSubmit = async (values: z.infer<typeof contentFormSchema>) => {
    if (isLoading) return;

    const { content } = await createContent({
      brandId: values.brandId,
      brief: values.brief,
      type: values.type,
    }).unwrap();

    onSuccess(content);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-3">
        <FormField
          control={form.control}
          name="type"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Content Type</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a content type" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="blog_post">Blog Post</SelectItem>
                  <SelectItem value="x_post">X/Twitter Post</SelectItem>
                  <SelectItem value="linkedin_post">LinkedIn Post</SelectItem>
                </SelectContent>
              </Select>
              <FormDescription>
                Select the type of content you'd like to generate.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="brief"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Brief</FormLabel>
              <FormControl>
                <Input
                  placeholder="A blog post about content marketing."
                  {...field}
                />
              </FormControl>
              <FormDescription>
                Provide a brief description of the content you'd like to
                generate.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button type="submit" disabled={isLoading} className="min-w-20">
          {isLoading ? <RingLoader size={20} color="white" /> : "Submit"}
        </Button>
      </form>
    </Form>
  );
};
