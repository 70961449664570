import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import { Input } from "@/components/ui/input";
import axios from "axios";
import { useAuth } from "@/hooks/use-auth";
import { TeamMemberDto } from "@/dtos";
import { useEffect } from "react";

const teamMemberFormSchema = z.object({
  brandId: z.string().uuid(),
  email: z.string().email(),
});

export interface TeamMemberFormProps {
  brand: { name: string; id: string };
  onSuccess: (content: TeamMemberDto) => void;
  onError: (error: Error) => void;
}

export const TeamMemberForm = ({
  brand,
  onSuccess,
  onError,
}: TeamMemberFormProps): JSX.Element => {
  const { token } = useAuth();

  const form = useForm<z.infer<typeof teamMemberFormSchema>>({
    resolver: zodResolver(teamMemberFormSchema),
    defaultValues: {
      brandId: "",
      email: "",
    },
  });

  useEffect(() => {
    if (brand) {
      form.setValue("brandId", brand.id);
    }
  }, [brand, form]);

  const onSubmit = async (values: z.infer<typeof teamMemberFormSchema>) => {
    axios
      .post(
        `${import.meta.env.VITE_API_URL}/brands/${values.brandId}/team-members`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch((error) => {
        onError(error);
      });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-3">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input placeholder="Enter an email" {...field} />
              </FormControl>
              <FormDescription>
                Provide an email address for the team member you'd like to add.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button type="submit">Submit</Button>
      </form>
    </Form>
  );
};
