import { useEffect, useState } from "react";
import { useApi, UseApiResponse } from "../use-api";

export const useXLink = ({
  brandId,
}: {
  brandId?: string;
}): UseApiResponse<string> => {
  const [xLinkResponse, setXLinkResponse] = useState<UseApiResponse<string>>({
    response: null,
    error: null,
    loading: false,
    reload: () => {},
  });

  const { response, error, loading, reload } = useApi<unknown, unknown, string>(
    {
      endpoint: `brands/${brandId}/x/auth-link`,
      method: "get",
      lazy: !brandId,
    }
  );

  useEffect(() => {
    if (response !== null || error !== null) {
      setXLinkResponse({ response, error, loading, reload });
    }
  }, [response, error, loading, reload]);

  return xLinkResponse;
};
