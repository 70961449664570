import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { SidebarMenu } from "../sidebar-menu/sidebar-menu.component";
import { useState } from "react";
import { useAuth } from "@/hooks/use-auth";
import { useBrandContext } from "@/hooks";
import { LogOut } from "lucide-react";
import { Flex } from "@radix-ui/themes";
import { BrandSelection } from "../brand-selection";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/dialog";
import { BrandForm } from "../forms";
import { Alert, AlertDescription, AlertTitle } from "../ui/alert";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { TypographyH3 } from "../typography";
import { Separator } from "../ui/separator";
import { useGetBrandsQuery } from "@/redux";

export const Sidebar = (): JSX.Element => {
  const { token, user, logout } = useAuth();
  const { brand, setBrand } = useBrandContext();
  const {
    data: brandsResponse,
    isLoading: brandsLoading,
    refetch: reloadBrands,
  } = useGetBrandsQuery(undefined, { skip: !token || !user });
  const [brandFormOpen, setBrandFormOpen] = useState(false);
  const [brandFormError, setBrandFormError] = useState<Error | null>(null);

  const handleBrandFormSuccess = (brand: {
    id: string;
    name: string;
  }): void => {
    reloadBrands();
    setBrand(brand);
    setBrandFormOpen(false);
  };

  return (
    <div className="flex h-screen flex-col gap-2 pt-[10px] overflow-y-auto">
      <div className="flex h-14 items-center justify-center px-4 lg:h-[60px] lg:px-6">
        <Link to="/" className="flex items-center gap-2 font-semibold">
          <img src="/logo.svg" alt="Marketer AI" className="w-[200px]" />
        </Link>
      </div>

      <Flex
        justify="center"
        width="100%"
        px="4"
        py="4"
        gap="4"
        direction="column"
      >
        <TypographyH3 className="text-xs font-semibold uppercase text-muted-foreground">
          Brand
        </TypographyH3>
        <Flex gap="4">
          <BrandSelection
            brands={brandsResponse?.brands || []}
            disabled={brandsLoading || brandsResponse?.brands.length === 0}
            value={brand?.id}
            onSelect={(value) =>
              setBrand(
                brandsResponse!.brands
                  .map((b) => ({
                    id: b.id,
                    name: b.name,
                    subscriptionStatus: b.subscriptionStatus || "",
                  }))
                  .find((b) => b.id === value)!
              )
            }
          />
          <Button variant="default" onClick={() => setBrandFormOpen(true)}>
            New
          </Button>
        </Flex>
      </Flex>

      <Flex px="4">
        <Separator />
      </Flex>

      <div className="flex-1">
        <nav className="grid items-start px-2 pt-4 text-sm font-medium lg:px-4">
          <SidebarMenu px={3} py={2} />
        </nav>
      </div>

      <Flex justify="center" px="4" pb="2" direction="column" gap="4">
        <Flex justify="center">
          <Button variant="ghost" className="w-full" onClick={logout}>
            <LogOut size={20} />
            Logout
          </Button>
        </Flex>
      </Flex>

      <Dialog open={brandFormOpen} onOpenChange={setBrandFormOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Create Brand</DialogTitle>
          </DialogHeader>

          {brandFormError && (
            <Alert variant="destructive">
              <ExclamationTriangleIcon className="h-4 w-4" />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{brandFormError?.message}</AlertDescription>
            </Alert>
          )}

          <BrandForm
            onSuccess={handleBrandFormSuccess}
            onError={setBrandFormError}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};
