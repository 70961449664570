import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { Flex } from "@radix-ui/themes";
import { Alert, AlertTitle, AlertDescription } from "../ui/alert";
import { useContentTableDialog } from "../content-table/content-table-dialog.hook";

export const ContentErrors = ({
  error,
}: {
  error: string | undefined;
}): JSX.Element => {
  const { error: dialogError } = useContentTableDialog();

  if (!error && !dialogError) {
    return <></>;
  }

  return (
    <Flex pb="3">
      <Alert variant="destructive">
        <ExclamationTriangleIcon className="h-4 w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error || dialogError}</AlertDescription>
      </Alert>
    </Flex>
  );
};
