import { OnboardingDto } from "@/dtos";
import { baseApi } from "./base-api.slice";

const baseApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ["Onboarding"],
});

const onboardingApi = baseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getOnboardingStatus: builder.query<OnboardingDto, void>({
      query: () => "/onboarding/status",
      providesTags: ["Onboarding"],
    }),
    skipOnboarding: builder.mutation<void, void>({
      query: () => ({
        url: "/onboarding/skip",
        method: "POST",
      }),
      invalidatesTags: ["Onboarding"],
    }),
  }),
});

export const { useGetOnboardingStatusQuery, useSkipOnboardingMutation } =
  onboardingApi;

export const { invalidateTags: invalidateOnboardingTags } = onboardingApi.util;
