import { useEffect, useState } from "react";
import { useApi, UseApiResponse } from "../use-api";

export const useLinkedinStatus = ({
  brandId,
}: {
  brandId?: string;
}): UseApiResponse<{ status: "connected" | "not_connected" }> => {
  const [linkedinStatusResponse, setLinkedinStatusResponse] = useState<
    UseApiResponse<{ status: "connected" | "not_connected" }>
  >({
    response: null,
    error: null,
    loading: false,
    reload: () => {},
  });

  const { response, error, loading, reload } = useApi<
    unknown,
    unknown,
    { status: "connected" | "not_connected" }
  >({
    endpoint: `brands/${brandId}/linkedin/status`,
    method: "get",
    lazy: !brandId,
  });

  useEffect(() => {
    if (response !== null || error !== null) {
      setLinkedinStatusResponse({ response, error, loading, reload });
    }
  }, [response, error, loading, reload]);

  return linkedinStatusResponse;
};
