import { useEffect } from "react";
import { Flex } from "@radix-ui/themes";
import { useBrandContext } from "@/hooks";
import { useSearchParams } from "react-router-dom";

import { ContentErrors } from "@/components";
import { ContentPublishedComponent } from "@/components/content/published";
import { useGetBrandsQuery, useGetContentQuery } from "@/redux";

export const ContentPublished = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const { brand, setBrand } = useBrandContext();

  const { data: brands, error: brandsLoadingError } = useGetBrandsQuery();

  const {
    data: content,
    refetch: reloadContent,
    error: contentLoadingError,
  } = useGetContentQuery(
    {
      brandId: brand?.id || "",
    },
    {
      skip: !brand?.id,
    }
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      reloadContent();
    }, 10000);

    return () => clearInterval(intervalId);
  }, [reloadContent]);

  useEffect(() => {
    if (!brands) return;
    if (!searchParams.get("brandId")) return;

    setBrand(brands!.brands.find((b) => b.id === searchParams.get("brandId"))!);
  }, [searchParams, brands, setBrand]);

  const published = content?.contents.filter((c) => c.status === "final");

  return (
    <>
      <Flex justify="between" pb="3">
        <h1 className="text-lg font-semibold md:text-2xl">Ideas</h1>
      </Flex>

      <ContentErrors
        error={
          (brandsLoadingError && "message" in brandsLoadingError
            ? brandsLoadingError.message
            : undefined) ||
          (contentLoadingError && "message" in contentLoadingError
            ? contentLoadingError.message
            : undefined) ||
          undefined
        }
      />

      <Flex height="100%">
        {(!published || published?.length === 0) && (
          <div className="flex flex-1 flex-col items-center justify-center gap-1 text-center">
            <h3 className="text-2xl font-bold tracking-tight">
              You have no published content yet
            </h3>
          </div>
        )}

        {published && published?.length > 0 && (
          <Flex flexGrow="1" direction="column" width="100%">
            <Flex width="100%">
              <ContentPublishedComponent
                contentItems={published.map((item) => ({
                  id: item.id,
                  createdAt: item.createdAt,
                  brand: brand!,
                  brief: item.brief,
                  type: item.type,
                  status: item.status,
                  targetDate: item.targetDate,
                  location: `/brands/${brand?.id}/content/${item.id}`,
                }))}
              />
            </Flex>
          </Flex>
        )}
      </Flex>
    </>
  );
};
