import { DomainDto } from "@/dtos";
import { baseApi } from "./base-api.slice";

const baseApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ["Domain"],
});

export const domainsApi = baseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getDomains: builder.query<{ domains: DomainDto[] }, { brandId: string }>({
      query: ({ brandId }) => `/brands/${brandId}/domains`,
      providesTags: ["Domain"],
    }),
    getDomain: builder.query<
      { domain: DomainDto },
      { brandId: string; domainId: string }
    >({
      query: ({ brandId, domainId }) =>
        `/brands/${brandId}/domains/${domainId}`,
      providesTags: ["Domain"],
    }),
    createDomain: builder.mutation<
      { domain: DomainDto },
      {
        brandId: string;
        domain: string;
      }
    >({
      query: ({ brandId, domain }) => ({
        url: `/brands/${brandId}/domains`,
        method: "POST",
        body: { domain },
      }),
      invalidatesTags: ["Domain"],
    }),
    checkDomain: builder.mutation<
      { domain: DomainDto },
      { domainId: string; brandId: string }
    >({
      query: ({ domainId, brandId }) => ({
        url: `/brands/${brandId}/domains/${domainId}/check`,
        method: "PUT",
      }),
      invalidatesTags: ["Domain"],
    }),
    deleteDomain: builder.mutation<void, { domainId: string; brandId: string }>(
      {
        query: ({ domainId, brandId }) => ({
          url: `/brands/${brandId}/domains/${domainId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Domain"],
      }
    ),
  }),
});

export const {
  useGetDomainsQuery,
  useGetDomainQuery,
  useCreateDomainMutation,
  useCheckDomainMutation,
  useDeleteDomainMutation,
} = domainsApi;
