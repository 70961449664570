import { ContentForm, ScheduleForm } from "@/components/forms";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useEffect, useState } from "react";
import { Flex } from "@radix-ui/themes";
import { useBrandContext, useNotificationsContext } from "@/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ContentErrors } from "@/components";
import {
  ContentIdeasComponent,
  ContentIdeasDialogProvider,
} from "@/components/content/ideas";
import { AxiosError } from "axios";
import { useGetBrandsQuery, useGetContentQuery } from "@/redux";
import { useAuth } from "@/hooks/use-auth";

export const ContentIdeas = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const { brand, setBrand } = useBrandContext();
  const { addNotification, addError } = useNotificationsContext();
  const navigate = useNavigate();
  const { token, user } = useAuth();
  const [error, setError] = useState<Error | null>(null);
  const [addIdeaDialogOpen, setAddIdeaDialogOpen] = useState(false);
  const [generateIdeasDialogOpen, setGenerateIdeasDialogOpen] = useState(false);

  const { data: brands, error: brandsLoadingError } = useGetBrandsQuery(
    undefined,
    {
      skip: !token || !user,
    }
  );

  const {
    data: content,
    refetch: reloadContent,
    error: contentLoadingError,
  } = useGetContentQuery(
    {
      brandId: brand?.id || "",
    },
    {
      skip: !brand?.id,
    }
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      reloadContent();
    }, 10000);

    return () => clearInterval(intervalId);
  }, [reloadContent]);

  useEffect(() => {
    if (!brands) return;
    if (!searchParams.get("brandId")) return;

    setBrand(brands!.brands.find((b) => b.id === searchParams.get("brandId"))!);
  }, [searchParams, brands, setBrand]);

  useEffect(() => {
    if (!error) return;

    addError({ message: error.message });
  }, [error, addError]);

  const onCreateContentSuccess = ({ contentId }: { contentId: string }) => {
    reloadContent();
    setAddIdeaDialogOpen(false);
    navigate(`/brands/${brand?.id}/content/${contentId}`);
  };

  const onGenerateIdeasSuccess = () => {
    setGenerateIdeasDialogOpen(false);

    addNotification({
      message:
        "Your ideas are being generated! We'll let you know when they are ready.",
    });
  };

  const ideas = content?.contents.filter((c) => c.status === "idea");

  return (
    <ContentIdeasDialogProvider onSuccess={reloadContent}>
      <Flex justify="between" pb="3">
        <h1 className="text-lg font-semibold md:text-2xl">Ideas</h1>
      </Flex>

      <ContentErrors
        error={
          (brandsLoadingError && "message" in brandsLoadingError
            ? brandsLoadingError.message
            : undefined) ||
          (contentLoadingError && "message" in contentLoadingError
            ? contentLoadingError.message
            : undefined) ||
          undefined
        }
      />

      <Flex height="100%">
        {(!ideas || ideas?.length === 0) && (
          <div className="flex flex-1 flex-col items-center justify-center gap-1 text-center">
            <h3 className="text-2xl font-bold tracking-tight">
              You have no ideas yet
            </h3>
            <p className="text-sm text-muted-foreground">
              Click the button below to start generating ideas.
            </p>
            <Flex gap="2">
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger>
                    <Button
                      className={`mt-4 ${
                        brand === null ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                      onClick={() => setGenerateIdeasDialogOpen(true)}
                    >
                      ✨ Generate Ideas
                    </Button>
                  </TooltipTrigger>
                  {brand === null && (
                    <TooltipContent>
                      <p>You must select a brand to generate ideas</p>
                    </TooltipContent>
                  )}
                </Tooltip>
              </TooltipProvider>

              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger>
                    <Button
                      className={`mt-4 ${
                        brand === null ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                      onClick={() => setAddIdeaDialogOpen(true)}
                    >
                      Add an Idea
                    </Button>
                  </TooltipTrigger>
                  {brand === null && (
                    <TooltipContent>
                      <p>You must select a brand to add an idea</p>
                    </TooltipContent>
                  )}
                </Tooltip>
              </TooltipProvider>
            </Flex>
          </div>
        )}

        {ideas && ideas?.length > 0 && (
          <Flex flexGrow="1" direction="column" width="100%">
            <Flex width="100%">
              <ContentIdeasComponent
                contentItems={ideas.map((item) => ({
                  id: item.id,
                  createdAt: item.createdAt,
                  brand: brand!,
                  brief: item.brief,
                  type: item.type,
                  status: item.status,
                  targetDate: item.targetDate,
                  location: `/brands/${brand?.id}/content/${item.id}`,
                  isApproved: item.isApproved,
                }))}
                addIdea={() => setAddIdeaDialogOpen(true)}
                generateIdeas={() => setGenerateIdeasDialogOpen(true)}
              />
            </Flex>
          </Flex>
        )}

        <Flex>
          <Dialog
            open={generateIdeasDialogOpen}
            onOpenChange={setGenerateIdeasDialogOpen}
          >
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Generate Ideas</DialogTitle>
              </DialogHeader>
              <ScheduleForm
                brand={brand!}
                onSuccess={onGenerateIdeasSuccess}
                onError={(error) => {
                  if (error instanceof AxiosError) {
                    setError(new Error(error.response?.data.message));
                  } else {
                    setError(
                      new Error("Something went wrong. Please try again later.")
                    );
                  }
                  setGenerateIdeasDialogOpen(false);
                }}
              />
            </DialogContent>
          </Dialog>
        </Flex>

        <Flex>
          <Dialog open={addIdeaDialogOpen} onOpenChange={setAddIdeaDialogOpen}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Add an Idea</DialogTitle>
              </DialogHeader>
              <ContentForm
                brand={brand!}
                onSuccess={(content) =>
                  onCreateContentSuccess({ contentId: content.id })
                }
              />
            </DialogContent>
          </Dialog>
        </Flex>
      </Flex>
    </ContentIdeasDialogProvider>
  );
};
