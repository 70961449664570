import { TypographyH4, TypographyP } from "@/components/typography";
import { cn } from "@/lib/utils";
import { Flex } from "@radix-ui/themes";

export interface OnboardingStepComponentProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  active: boolean;
}

export const OnboardingStepComponent = ({
  icon,
  title,
  description,
  active,
}: OnboardingStepComponentProps): JSX.Element => {
  return (
    <Flex justify="start" align="center" width="100%" gap="24px">
      {icon}
      <Flex direction="column">
        <TypographyH4 className={cn(active ? "text-white" : "text-gray-500")}>
          {title}
        </TypographyH4>
        <TypographyP className={cn(active ? "text-white" : "text-gray-500")}>
          {description}
        </TypographyP>
      </Flex>
    </Flex>
  );
};
