import { Flex } from "@radix-ui/themes";
import { useOnboardingContext } from "@/hooks";
import { useEffect } from "react";
import { useAuth } from "@/hooks/use-auth";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { decodeJwt } from "jose";
import { OnboardingStepComponent } from "@/components";
import { Brain, PersonStanding, Rocket } from "lucide-react";
import { Progress } from "@/components/ui/progress";
import { OnboardingSkipContainer } from "@/containers";
import { cn } from "@/lib/utils";

export const OnboardingLayout = (): JSX.Element => {
  const { token } = useAuth();
  const { onboarding } = useOnboardingContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!onboarding) return;
    if (onboarding.status === "FAILED_SCHEDULE") {
      navigate("/onboarding/schedule");
    }
    if (onboarding.status === "COMPLETED") {
      navigate("/onboarding/success");
    }
    if (onboarding.status === "CREATE_SCHEDULE") {
      navigate("/onboarding/schedule");
    }
    if (
      onboarding.status === "CREATE_CONTENT" ||
      onboarding.status === "GENERATING_SCHEDULE" ||
      onboarding.status === "FAILED_CONTENT"
    ) {
      navigate("/onboarding/content");
    }
    if (onboarding.status === "GENERATING_CONTENT") {
      navigate("/onboarding/success");
    }
    if (onboarding.status === "SKIPPED") {
      navigate("/");
    }
  }, [onboarding, navigate]);

  useEffect(() => {
    // Check local storage for token here, otherwise on first load token will be
    // undefined if this runs before the AuthProvider has refreshed the token from
    // local storage
    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/auth/login");
    }

    const { exp } = decodeJwt(token!);

    if (!exp) {
      navigate("/auth/login");
    }

    if (exp! * 1000 < new Date().getTime()) {
      navigate("/auth/login");
    }
  }, [token, navigate]);

  const progress = () => {
    switch (onboarding?.status) {
      case "COMPLETED":
        return 100;
      case "CREATE_BRAND":
        return 0;
      case "CREATE_SCHEDULE":
        return 25;
      case "FAILED_SCHEDULE":
        return 25;
      case "GENERATING_SCHEDULE":
        return 30;
      case "CREATE_CONTENT":
        return 50;
      case "FAILED_CONTENT":
        return 50;
      case "GENERATING_CONTENT":
        return 60;
      default:
        return 0;
    }
  };

  return (
    <Flex justify="center" align="center" className="h-[100vh]">
      <Flex
        display={{ initial: "none", sm: "flex" }}
        width={{ initial: "0%", sm: "30%" }}
        height="100%"
      >
        <Flex
          style={{ background: "black" }}
          height="100%"
          width="100%"
          justify="between"
          align="start"
          p="24px"
          direction="column"
        >
          <Flex>
            <img src="/logolight.svg" alt="Marketer AI" className="w-[200px]" />
          </Flex>

          <Flex direction="column" gap="36px" width="100%">
            <OnboardingStepComponent
              icon={
                <PersonStanding
                  className={cn(
                    onboarding?.status === "COMPLETED" ||
                      onboarding?.status === "CREATE_BRAND"
                      ? "text-white"
                      : "text-gray-500"
                  )}
                  size={60}
                />
              }
              title="Setup you brand"
              description="Help us to understand more about your brand"
              active={
                onboarding?.status === "CREATE_BRAND" ||
                onboarding?.status === "COMPLETED"
              }
            />

            <OnboardingStepComponent
              icon={
                <Brain
                  className={cn(
                    onboarding?.status === "COMPLETED" ||
                      onboarding?.status === "CREATE_SCHEDULE" ||
                      onboarding?.status === "FAILED_SCHEDULE"
                      ? "text-white"
                      : "text-gray-500"
                  )}
                  size={60}
                />
              }
              title="Generate a content plan"
              description="Create some content ideas and a release calendar"
              active={
                onboarding?.status === "CREATE_SCHEDULE" ||
                onboarding?.status === "GENERATING_SCHEDULE" ||
                onboarding?.status === "FAILED_SCHEDULE" ||
                onboarding?.status === "COMPLETED"
              }
            />

            <OnboardingStepComponent
              icon={
                <Rocket
                  className={cn(
                    onboarding?.status === "COMPLETED" ||
                      onboarding?.status === "CREATE_CONTENT" ||
                      onboarding?.status === "FAILED_CONTENT"
                      ? "text-white"
                      : "text-gray-500"
                  )}
                  size={60}
                />
              }
              title="Generate your content"
              description="Generate the content, including text and images"
              active={
                onboarding?.status === "CREATE_CONTENT" ||
                onboarding?.status === "GENERATING_CONTENT" ||
                onboarding?.status === "FAILED_CONTENT" ||
                onboarding?.status === "COMPLETED"
              }
            />
          </Flex>

          <Flex style={{ color: "white" }} justify="between" width="100%">
            <Link to="https://getmarketerai.com">Back to home</Link>
            <OnboardingSkipContainer />
          </Flex>
        </Flex>
      </Flex>
      <Flex
        width={{ initial: "100%", sm: "70%" }}
        height="100%"
        p="12px"
        pl="0px"
        justify={"center"}
      >
        <Flex
          width="100%"
          height="100%"
          justify="center"
          align="center"
          direction="column"
          gap="24px"
          p="24px"
        >
          <Flex justify="center" align="center" flexGrow="1" width="100%">
            <Outlet />
          </Flex>
          <Flex justify="center" align="center" width="50%">
            <Progress value={progress()} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
