import { Flex } from "@radix-ui/themes";
import { ColumnDef } from "@tanstack/react-table";

export type TeamMembersTableItem = {
  id: string;
  email: string;
};

export const columns: ColumnDef<TeamMembersTableItem>[] = [
  {
    accessorKey: "email",
    header: "Email",
    cell: ({ row }) => {
      return <Flex align="center">{row.original.email}</Flex>;
    },
  },
];
