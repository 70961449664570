import { Button } from "@/components/ui/button";
import { Flex } from "@radix-ui/themes";
import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { toast } from "sonner";

export const SentryProvider = ({ children }: { children: React.ReactNode }) => {
  // TODO: Store this in the database and allow users to change their mind at a later point
  // by toggling a setting in the app.
  const [hasConsented, setHasConsented] = useState<boolean>(
    Boolean(localStorage.getItem("telemetry_consent") || false)
  );
  const [hasDismissed, setHasDismissed] = useState<boolean>(
    Boolean(localStorage.getItem("telemetry_dismissed") || false)
  );

  useEffect(() => {
    if (!hasConsented && !hasDismissed) {
      toast.custom(
        (t) => (
          <Flex
            direction="column"
            gap="4"
            p="4"
            justify="between"
            align="center"
          >
            <p className="text-sm">
              We use telemetry to collect anonymous bug reports to improve our
              product's stability. This is optional and is opt in. Would you
              like to consent to telemetry? You can continue using the app
              regardless of your choice.
            </p>
            <Flex gap="2" justify="between">
              <Button
                variant="outline"
                onClick={() => {
                  localStorage.setItem("telemetry_dismissed", "true");
                  setHasDismissed(true);
                  toast.dismiss(t);
                }}
              >
                No thanks!
              </Button>
              <Button
                onClick={() => {
                  localStorage.setItem("telemetry_consent", "true");
                  setHasConsented(true);
                  toast.dismiss(t);
                }}
              >
                Sure!
              </Button>
            </Flex>
          </Flex>
        ),
        {
          duration: Infinity,
        }
      );
    }
  }, []);

  useEffect(() => {
    if (hasConsented) {
      try {
        Sentry.init({
          dsn: "https://501570c4aea83b4e0d6965d6b07681f1@o4507748008198144.ingest.de.sentry.io/4507748010491984",
          integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
          ],
          // Performance Monitoring
          tracesSampleRate: 1.0, //  Capture 100% of the transactions
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [
            "localhost",
            /^https:\/\/app\.getmarketerai\.com/,
          ],
          // Session Replay
          replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
          replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
      } catch (error) {
        console.error("Error initializing Sentry", error);
      }
    }
  }, [hasConsented]);

  return <>{children}</>;
};
