import {
  ErrorComponent,
  TypographyH2,
  TypographyP,
  ContentCardComponent,
} from "@/components";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { ContentDto } from "@/dtos";
import { useOnboardingContext } from "@/hooks";
import { useAuth } from "@/hooks/use-auth";
import { Flex } from "@radix-ui/themes";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { RingLoader } from "react-spinners";

declare global {
  interface Window {
    plausible: (eventName: string) => void;
  }
}

export const OnboardingContentPage = () => {
  const { token } = useAuth();
  const { reload, onboarding } = useOnboardingContext();
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [contentIds, setContentIds] = useState<string[]>([]);

  const poll = useCallback(() => {
    setInterval(() => {
      reload();
    }, 5000);
  }, [reload]);

  const onSubmit = async ({ contentIds }: { contentIds: string[] }) => {
    try {
      const { status } = await axios.post(
        `${import.meta.env.VITE_API_URL}/onboarding/content`,
        {
          contentIds,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (status !== 201) {
        throw new Error("Failed to submit content");
      }

      window.plausible("onboarding-content-submitted");

      reload();
    } catch (error) {
      setSubmitError("Failed to submit content");
    }
  };

  const onSelect = (content: ContentDto) => {
    if (contentIds.includes(content.id)) {
      setContentIds(contentIds.filter((id) => id !== content.id));
    } else if (contentIds.length < 3) {
      setContentIds([...contentIds, content.id]);
    }
  };

  const getIcon = (content: ContentDto): string => {
    switch (content.type) {
      case "blog_post":
        return "/blog.png";
      case "x_post":
        return "/x.png";
      case "linkedin_post":
        return "/linkedin.png";
      default:
        return "/blog.png";
    }
  };

  const getSelected = (content: ContentDto): boolean => {
    return contentIds.includes(content.id);
  };

  const isScheduleSucceeded = onboarding?.schedule?.status === "succeeded";
  const isScheduleGenerating = onboarding?.schedule?.status === "generating";

  useEffect(() => {
    if (onboarding?.schedule?.status === "generating") {
      poll();
    }
  }, [onboarding?.schedule?.status, poll]);

  return (
    <Flex direction="column" gap="24px" width="100%">
      <Flex direction="column" gap="24px" width="100%">
        {submitError && (
          <ErrorComponent title="Error" description={submitError} />
        )}
        <TypographyH2 className="border-none pb-0 mb-0">
          Generate your content
        </TypographyH2>
        <TypographyP className="pt-0">
          {isScheduleSucceeded
            ? "Select three pieces of content to get started."
            : "Our AI is currently crafting tailored content ideas for your brand. This process ensures high-quality, relevant suggestions. This won't take long, please stand by!"}
        </TypographyP>
        <Flex
          direction="row"
          gap="24px"
          wrap="wrap"
          width="100%"
          justify="between"
          align="center"
          overflow="scroll"
          maxHeight="65vh"
        >
          {onboarding &&
            isScheduleSucceeded &&
            onboarding.content.map((content: ContentDto) => (
              <Flex
                direction="column"
                key={content.id}
                justify="between"
                align="center"
                width="30%"
                style={{
                  cursor:
                    getSelected(content) || contentIds.length < 3
                      ? "pointer"
                      : "default",
                }}
                onClick={() => onSelect(content)}
              >
                <ContentCardComponent
                  selected={getSelected(content)}
                  icon={getIcon(content)}
                  brief={content.brief}
                />
              </Flex>
            ))}
          {isScheduleGenerating && (
            <Flex
              direction="row"
              gap="24px"
              wrap="wrap"
              width="100%"
              justify="between"
              align="center"
            >
              {[...Array(6)].map((_, index) => (
                <Flex key={index} direction="column" width="30%" height="200px">
                  <Skeleton className="h-full flex justify-center items-center">
                    <RingLoader color="#000" size={50} />
                  </Skeleton>
                </Flex>
              ))}
            </Flex>
          )}
        </Flex>
        <Flex justify="start">
          <Button
            onClick={() => onSubmit({ contentIds })}
            disabled={
              contentIds.length < 3 ||
              onboarding?.schedule?.status !== "succeeded"
            }
          >
            Continue
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
