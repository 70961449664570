import { Flex } from "@radix-ui/themes";
import { columns, TeamMembersTableItem } from "./columns";
import { useState } from "react";
import { DataTableV2 } from "../datatable-v2";

export const TeamMembersTable = ({
  teamMembers,
  addTeamMember,
}: {
  teamMembers: TeamMembersTableItem[];
  addTeamMember: () => void;
}): JSX.Element => {
  const [pageSize] = useState(() => {
    const screenHeight = window.innerHeight;
    if (screenHeight < 600) {
      return 5; // Smaller devices
    } else if (screenHeight < 900) {
      return 8; // Medium devices
    } else {
      return 10; // Larger screens
    }
  });

  return (
    <Flex direction="column" gap="4" width="100%" height="100%">
      <DataTableV2
        columns={columns}
        data={teamMembers}
        pageSize={pageSize}
        actions={[{ name: "Add Team Member", action: addTeamMember }]}
      />
    </Flex>
  );
};
