import { TeamMemberForm } from "@/components";
import { ErrorComponent } from "@/components/error";
import { TeamMembersTable } from "@/components/team-members-table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useBrandContext, useTeamMembers } from "@/hooks";
import { Flex } from "@radix-ui/themes";
import { useState } from "react";

export const TeamMembersContainer = (): JSX.Element => {
  const { brand } = useBrandContext();
  const { response, error, reload } = useTeamMembers({
    brandId: brand?.id ?? "",
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addTeamMemberError, setAddTeamMemberError] = useState<Error | null>(
    null
  );

  const formatedError =
    error || addTeamMemberError
      ? (error || addTeamMemberError) instanceof Error
        ? {
            title: "Error",
            description: (error || addTeamMemberError)?.message,
          }
        : {
            title: "Error",
            description: "Something went wrong. Please try again later.",
          }
      : null;

  if (!response) {
    return <Flex>No team members</Flex>;
  }

  const addTeamMemberSuccess = () => {
    setDialogOpen(false);
    reload();
  };

  return (
    <>
      <Flex justify="between" pb="3">
        <h1 className="text-lg font-semibold md:text-2xl">Team Members</h1>
      </Flex>
      {formatedError && (
        <Flex py="12px">
          <ErrorComponent
            title={formatedError.title}
            description={
              formatedError.description ||
              "Something went wrong. Please try again later."
            }
          />
        </Flex>
      )}
      <Flex flexGrow="1" direction="column" width="100%">
        <Flex width="100%">
          <TeamMembersTable
            teamMembers={response?.teamMembers.map((item) => ({
              id: item.id,
              email: item.email,
            }))}
            addTeamMember={() => setDialogOpen(true)}
          />
        </Flex>
      </Flex>

      <Flex>
        <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Add Content</DialogTitle>
            </DialogHeader>
            <TeamMemberForm
              brand={brand!}
              onSuccess={addTeamMemberSuccess}
              onError={(error) => {
                setDialogOpen(false);
                setAddTeamMemberError(error);
              }}
            />
          </DialogContent>
        </Dialog>
      </Flex>
    </>
  );
};
