import { Button } from "@/components/ui/button";
import {
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { useAuth } from "@/hooks/use-auth";
import { Flex } from "@radix-ui/themes";
import axios, { AxiosError } from "axios";
import { useState } from "react";

export interface DeleteContainerProps {
  brandId?: string;
  contentBrief?: string;
  contentId?: string;
  onSuccess: () => void;
  onError: (error: Error) => void;
  onCancel: () => void;
}

export const DeleteContainer = ({
  brandId,
  contentBrief,
  contentId,
  onSuccess,
  onError,
  onCancel,
}: DeleteContainerProps): JSX.Element => {
  const { token } = useAuth();
  const [confirmed, setConfirmed] = useState(false);

  const handleDelete = async (): Promise<void> => {
    try {
      const { status } = await axios.delete(
        `${
          import.meta.env.VITE_API_URL
        }/brands/${brandId}/content/${contentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (status === 200) {
        onSuccess();
      } else {
        onError(new Error("Something went wrong. Please try again later."));
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        onError(new Error(error.response?.data.message));
      } else {
        onError(new Error("Something went wrong. Please try again later."));
      }
    }
  };

  return (
    <>
      <DialogHeader>
        <DialogTitle>Delete</DialogTitle>
      </DialogHeader>

      <DialogDescription>
        Are you sure you would like to delete the content{" "}
        <strong>"{contentBrief}"?</strong>
      </DialogDescription>

      <Input
        type="text"
        placeholder="Type confirm to delete the content"
        onChange={(e) => setConfirmed(e.target.value === "confirm")}
      />

      <Flex justify="between">
        <Button
          variant="destructive"
          onClick={handleDelete}
          disabled={!confirmed}
        >
          Delete
        </Button>
        <Button variant="default" onClick={onCancel}>
          Cancel
        </Button>
      </Flex>
    </>
  );
};
