import { useContext } from "react";
import {
  BrandContext,
  BrandContextType,
} from "../../contexts/brand/brand.context";

export const useBrandContext = (): BrandContextType => {
  const context = useContext(BrandContext);
  if (context === undefined) {
    throw new Error("useBrand must be used within a BrandProvider");
  }
  return context;
};
