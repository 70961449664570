import { ColumnDef } from "@tanstack/react-table";
import { formatDate } from "date-fns";
import { Flex } from "@radix-ui/themes";

import { Ellipsis } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ContentIdeasDialogTrigger } from "./content-ideas-dialog-trigger.component";

export type ContentIdeaTableItem = {
  id: string;
  createdAt: Date;
  brand: { id: string; name: string };
  brief: string;
  type: string;
  status: string;
  isApproved: boolean;
  targetDate?: Date;
  latestVersion?: Date;
  location?: string;
};

export const columns: ColumnDef<ContentIdeaTableItem>[] = [
  {
    accessorKey: "brief",
    header: "Brief",
    cell: ({ row }) => {
      return <Flex align="center">{row.original.brief}</Flex>;
    },
  },
  {
    accessorKey: "type",
    header: ({ column }) => {
      return (
        <Flex align="center" justify="center">
          <Button
            variant="ghost"
            onClick={() => {
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Type
          </Button>
        </Flex>
      );
    },
    cell: ({ row }) => {
      return (
        <Flex align="center" justify="center">
          {row.original.type}
        </Flex>
      );
    },
  },
  {
    accessorKey: "targetDate",
    header: ({ column }) => {
      return (
        <Flex align="center" justify="center">
          <Button
            variant="ghost"
            onClick={() => {
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Publish Date
          </Button>
        </Flex>
      );
    },
    cell: ({ row }) => {
      return (
        <Flex align="center" justify="center">
          {row.original.targetDate
            ? formatDate(row.original.targetDate, "PPP")
            : "No publish date"}
        </Flex>
      );
    },
  },
  {
    accessorKey: "actions",
    header: "Actions",
    cell: ({ row }) => {
      return (
        <Flex className="w-[100px]">
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Button variant="ghost">
                <Ellipsis />
              </Button>
            </DropdownMenuTrigger>

            <DropdownMenuContent>
              <ContentIdeasDialogTrigger
                content={row.original}
                text="Generate Draft"
                action="generate"
              />
              <ContentIdeasDialogTrigger
                content={row.original}
                text="Delete"
                action="delete"
              />
            </DropdownMenuContent>
          </DropdownMenu>
        </Flex>
      );
    },
  },
];
