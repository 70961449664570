import { SignupContainer } from "@/containers";
import { Flex } from "@radix-ui/themes";

export const SignupPage = (): JSX.Element => {
  return (
    <Flex
      width="100%"
      height="100%"
      align="center"
      justify="center"
      direction="column"
      gap="6"
    >
      <SignupContainer />
    </Flex>
  );
};
