import {
  ErrorComponent,
  ScheduleFormV2,
  TypographyH2,
  TypographyP,
} from "@/components";
import { useOnboardingContext } from "@/hooks";
import { useAuth } from "@/hooks/use-auth";
import { Flex } from "@radix-ui/themes";
import axios from "axios";
import { useState } from "react";

declare global {
  interface Window {
    plausible: (eventName: string) => void;
  }
}

export const OnboardingSchedulePage = () => {
  const { token } = useAuth();
  const { reload } = useOnboardingContext();
  const [submitError, setSubmitError] = useState<string | null>(null);

  const onSubmit = async ({ name, goal }: { name: string; goal: string }) => {
    try {
      const { status } = await axios.post(
        `${import.meta.env.VITE_API_URL}/onboarding/schedule`,
        {
          name,
          goal,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (status !== 201) {
        throw new Error("Something went wrong. Please try again.");
      }

      window.plausible("onboarding-ideas-submitted");

      reload();
    } catch (error) {
      if (error instanceof Error) {
        setSubmitError(error.message);
      } else {
        setSubmitError("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <Flex direction="column" gap="24px" width="100%">
      <Flex direction="column" gap="12px" width="100%">
        {submitError && (
          <ErrorComponent title="Error" description={submitError} />
        )}
        <TypographyH2 className="border-none pb-0 mb-0">
          Generate a content plan
        </TypographyH2>
        <TypographyP className="pt-0">
          Automatically create a backlog of content ideas.
        </TypographyP>
      </Flex>
      <ScheduleFormV2 onSubmit={onSubmit} />
    </Flex>
  );
};
