import { type Editor } from "@tiptap/react";
import { EditorMenu } from "./menu";

type ToolbarProps = {
  connected: boolean;
  saving: boolean;
  lastSaved: string | null;
  editor: Editor | null;
  enableImages?: boolean;
};

const Toolbar = ({
  editor,
  connected,
  saving,
  lastSaved,
  enableImages = true,
}: ToolbarProps) => {
  if (!editor) {
    return null;
  }

  return (
    <EditorMenu
      editor={editor}
      connected={connected}
      saving={saving}
      lastSaved={lastSaved}
      enableImages={enableImages}
    />
  );
};

export default Toolbar;
