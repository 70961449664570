import { useEditor, EditorContent, BubbleMenu } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Heading from "@tiptap/extension-heading";
import Image from "@tiptap/extension-image";
import Toolbar from "./toolbar";
import BulletList from "@tiptap/extension-bullet-list";
import Link from "@tiptap/extension-link";
import { Flex } from "@radix-ui/themes";
import { EditorMenu } from "./menu";
import Paragraph from "@tiptap/extension-paragraph";
import { useEffect } from "react";

type TipTapProps = {
  connected: boolean;
  html: string;
  saving: boolean;
  lastSaved: string | null;
  onUpdate: ({ html }: { html: string }) => void;
  key: string;
  bubbleMenu?: boolean;
  editable?: boolean;
  isDraft?: boolean;
  enableImages?: boolean;
};

const Tiptap = ({
  connected,
  html,
  onUpdate,
  saving,
  lastSaved,
  key,
  bubbleMenu = true,
  editable = true,
  enableImages = true,
}: TipTapProps) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure(),
      Heading.configure({
        levels: [1, 2, 3, 4, 5, 6],
      }).extend({
        renderHTML({ node, HTMLAttributes }) {
          const level = node.attrs.level as 1 | 2 | 3 | 4 | 5 | 6;
          const classes = {
            1: "text-3xl font-bold py-4",
            2: "text-2xl font-semibold py-3",
            3: "text-xl font-medium py-2",
            4: "text-lg font-medium py-2",
            5: "text-base font-medium py-1",
            6: "text-sm font-medium py-1",
          };
          HTMLAttributes.class = classes[level];
          return [`h${level}`, HTMLAttributes, 0];
        },
      }),
      Paragraph.configure({
        HTMLAttributes: {
          class: "py-2",
        },
      }),
      BulletList.configure({
        HTMLAttributes: {
          class: "list-disc pl-6 py-2",
        },
      }),
      Image.configure({
        inline: true,
        HTMLAttributes: {
          class: "w-[50%] rounded-md",
        },
      }),
      Link.configure({
        openOnClick: true,
        autolink: true,
        defaultProtocol: "https",
        HTMLAttributes: {
          class: "text-blue-500 hover:underline",
        },
      }),
    ],
    content: html,
    editorProps: {
      attributes: {
        class: `p-4`,
      },
    },
    editable,
    onUpdate() {
      onUpdate({ html: editor?.getHTML() || "" });
    },
  });

  useEffect(() => {
    if (editor && html !== editor.getHTML()) {
      const { from, to } = editor.state.selection;
      editor.commands.setContent(html, false);
      editor.commands.setTextSelection({ from, to });
    }
  }, [html, editor]);

  useEffect(() => {
    if (!editable) {
      editor?.setOptions({ editable: false });
    }
  }, [editable, editor]);

  return (
    <Flex direction="column" className="border rounded-lg" key={key}>
      {editable && (
        <Toolbar
          editor={editor}
          connected={connected}
          saving={saving}
          lastSaved={lastSaved}
          enableImages={enableImages}
        />
      )}
      <EditorContent editor={editor} disabled={!editable} />
      {editor && bubbleMenu && (
        <BubbleMenu
          editor={editor}
          className="bg-white border rounded-lg w-[368px]"
        >
          <EditorMenu
            editor={editor}
            connected={connected}
            saving={saving}
            lastSaved={lastSaved}
            compact={true}
            enableImages={enableImages}
          />
        </BubbleMenu>
      )}
    </Flex>
  );
};

export default Tiptap;
