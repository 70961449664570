import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { ContentIdeaTableItem } from "./columns";
import { useContentIdeasDialog } from "./content-ideas-dialog.hook";

export interface ContentIdeasDialogTriggerProps {
  text: string;
  content: ContentIdeaTableItem;
  action: "generate" | "delete";
}

export const ContentIdeasDialogTrigger = ({
  text,
  content,
  action,
}: ContentIdeasDialogTriggerProps) => {
  const {
    setContent,
    openDialog: setOpen,
    setAction,
  } = useContentIdeasDialog();

  const openDialog = (event: Event) => {
    event.stopPropagation();
    setContent({
      id: content.id,
      createdAt: content.createdAt,
      brief: content.brief,
      type: content.type,
      status: content.status,
      versions: [],
      isApproved: content.isApproved,
    });
    setAction(action);
    setOpen();
  };

  return (
    <DropdownMenuItem
      onSelect={openDialog}
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
    >
      {text}
    </DropdownMenuItem>
  );
};
