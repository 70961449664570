import { Outlet, useNavigate } from "react-router-dom";
import { Sidebar } from "@/components";
import { useEffect } from "react";
import { useAuth } from "@/hooks/use-auth";
import { decodeJwt } from "jose";
import {
  initializeAuth,
  useGetBrandsQuery,
  useGetOnboardingStatusQuery,
} from "@/redux";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/redux";

export const AppLayout = (): JSX.Element => {
  const dispatch: AppDispatch = useDispatch(); // Explicitly type the dispatch
  const { token, user } = useAuth();
  const { data: brandsResponse } = useGetBrandsQuery(undefined, {
    skip: !token || !user,
  });
  const { data: onboardingResponse } = useGetOnboardingStatusQuery(undefined, {
    skip: !token || !user,
  });
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(initializeAuth());
  }, []);

  useEffect(() => {
    try {
      if (!token) {
        navigate("/auth/login");
      }

      const { exp } = decodeJwt(token!);

      if (!exp) {
        navigate("/auth/login");
      }

      if (exp! * 1000 < new Date().getTime()) {
        navigate("/auth/login");
      }
    } catch (error) {
      navigate("/auth/login");
    }
  }, [token, navigate]);

  useEffect(() => {
    if (!brandsResponse) return;
    if (!onboardingResponse) return;
    if (onboardingResponse.status === "SKIPPED") return;
    if (onboardingResponse.status === "COMPLETED") return;
    navigate("/onboarding");
  }, [onboardingResponse, brandsResponse]);

  return (
    <div className="grid min-h-screen w-full md:grid-cols-[220px_1fr] lg:grid-cols-[280px_1fr]">
      <div className="hidden border-r bg-muted/40 md:block">
        <Sidebar />
      </div>
      <div className="flex flex-col h-screen">
        <main className="flex-1 overflow-auto p-4 lg:p-6">
          <Outlet />
        </main>
      </div>
    </div>
  );
};
