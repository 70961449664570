import { TypographyH1, TypographyP } from "@/components";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import { useGoPasswordlessContext } from "@gopasswordless/sdk";
import { zodResolver } from "@hookform/resolvers/zod";
import { Flex } from "@radix-ui/themes";
import { Fingerprint } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { z } from "zod";
import { GoogleLogin } from "@react-oauth/google";
import { useAuth } from "@/hooks/use-auth";
import { decodeJwt } from "jose";

const LoginFormSchema = z.object({
  email: z.string().email(),
});

export const LoginContainer = (): JSX.Element => {
  const form = useForm<z.infer<typeof LoginFormSchema>>({
    resolver: zodResolver(LoginFormSchema),
    defaultValues: {
      email: "",
    },
  });

  const { login } = useGoPasswordlessContext();

  const { token, handleError, handleLogin } = useAuth();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [googleButtonWidth, setGoogleButtonWidth] = useState(400);

  const onEmailSubmit = async (
    data: z.infer<typeof LoginFormSchema>
  ): Promise<void> => {
    setIsLoading(true);

    try {
      await login({ username: data.email });
    } catch (error) {
      if (error instanceof Error) {
        handleError(error.message);
      } else {
        handleError("Something went wrong, please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!token) return;
    const decoded = decodeJwt(token);
    if (!decoded.exp) return;
    if (decoded.exp < Date.now() / 1000) return;
    navigate("/");
  }, [token]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 640) {
        // 'sm' breakpoint in most Tailwind configs
        setGoogleButtonWidth(300);
      } else {
        setGoogleButtonWidth(400);
      }
    };

    // Set initial width
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Flex
      direction="column"
      align="center"
      justify="between"
      width="100%"
      height="100%"
    >
      <Flex />

      <Flex
        direction="column"
        gap="6"
        align="center"
        justify="center"
        width={{ initial: "300px", sm: "400px" }}
      >
        <TypographyH1>Sign in</TypographyH1>

        <Flex
          direction="column"
          gap="2"
          align="center"
          justify="center"
          width="100%"
        >
          <GoogleLogin
            onSuccess={(credentialResponse) => {
              credentialResponse?.credential
                ? handleLogin(credentialResponse.credential)
                : handleError("No credential returned from Google login.");
            }}
            onError={() => {
              handleError("Google login failed");
            }}
            text="continue_with"
            logo_alignment="center"
            width={googleButtonWidth}
            useOneTap={true}
          />
        </Flex>

        <Flex align="center" justify="center" gap="6" width="100%">
          <Separator style={{ flex: 1 }} />
          <TypographyP className="pt-0">or</TypographyP>
          <Separator style={{ flex: 1 }} />
        </Flex>

        <Flex
          direction="column"
          gap="4"
          align="center"
          justify="center"
          width="100%"
        >
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onEmailSubmit)}
              className="w-full"
            >
              <Flex
                direction="column"
                gap="4"
                align="center"
                justify="center"
                width="100%"
              >
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem style={{ width: "100%" }}>
                      <FormControl>
                        <Input {...field} placeholder="john.doe@email.com" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <Button
                  variant="default"
                  style={{ width: "100%" }}
                  type="submit"
                  disabled={isLoading}
                >
                  <Flex align="center" justify="center" gap="2">
                    <Fingerprint />
                    Continue with biometrics
                  </Flex>
                </Button>
              </Flex>
            </form>
          </Form>
        </Flex>

        <Flex align="center" justify="center" gap="2">
          <TypographyP className="pt-0">Don't have an account?</TypographyP>
          <Link to="/auth/signup">Sign up.</Link>
        </Flex>
      </Flex>

      <Flex />
    </Flex>
  );
};
