import { Flex } from "@radix-ui/themes";
import {
  Calendar,
  Code,
  CreditCard,
  FileCheck2,
  FileEdit,
  Globe,
  Lightbulb,
  MailIcon,
  MessageCircle,
  Share2,
  Users,
} from "lucide-react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { TypographyH3, TypographyH4, TypographyP } from "../typography";
import { Dialog, DialogContent } from "../ui/dialog";
import { useState } from "react";

export interface SidebarMenuProps {
  px: number;
  py: number;
}

export const SidebarMenu = ({ px, py }: SidebarMenuProps): JSX.Element => {
  const location = useLocation();
  const [supportDialogOpen, setSupportDialogOpen] = useState(false);

  const getLinkClassName = (path: string) => {
    return `flex items-center gap-3 rounded-lg px-${px} py-${py} ${
      location.pathname === path
        ? "bg-muted text-primary"
        : "text-muted-foreground"
    } transition-all hover:text-primary`;
  };

  return (
    <>
      <Flex direction="column" mb="4">
        <TypographyH3 className="mb-2 text-xs font-semibold uppercase text-muted-foreground">
          Content
        </TypographyH3>
        <NavLink
          to="/content/schedule"
          className={getLinkClassName("/content/schedule")}
        >
          <Calendar className="h-4 w-4" />
          Schedule
        </NavLink>
        <NavLink
          to="/content/ideas"
          className={getLinkClassName("/content/ideas")}
        >
          <Lightbulb className="h-4 w-4" />
          Ideas
        </NavLink>
        <NavLink
          to="/content/drafts"
          className={getLinkClassName("/content/drafts")}
        >
          <FileEdit className="h-4 w-4" />
          Drafts
        </NavLink>
        <NavLink
          to="/content/published"
          className={getLinkClassName("/content/published")}
        >
          <FileCheck2 className="h-4 w-4" />
          Published
        </NavLink>
      </Flex>

      {/* Settings */}
      <Flex direction="column" mb="4">
        <TypographyH3 className="mb-2 text-xs font-semibold uppercase text-muted-foreground">
          Settings
        </TypographyH3>
        <NavLink
          to="/settings/billing"
          className={getLinkClassName("/settings/billing")}
        >
          <CreditCard className="h-4 w-4" />
          Billing
        </NavLink>
        <NavLink
          to="/settings/domains"
          className={getLinkClassName("/settings/domains")}
        >
          <Globe className="h-4 w-4" />
          Domains
        </NavLink>
        <NavLink
          to="/integrations"
          className={getLinkClassName("/integrations")}
        >
          <Share2 className="h-4 w-4" />
          Social Media
        </NavLink>
        <NavLink to="/api-keys" className={getLinkClassName("/api-keys")}>
          <Code className="h-4 w-4" />
          API
        </NavLink>
      </Flex>

      {/* Team Management */}
      <Flex direction="column" mb="4">
        <TypographyH3 className="mb-2 text-xs font-semibold uppercase text-muted-foreground">
          Team
        </TypographyH3>
        <NavLink
          to="/team-members"
          className={getLinkClassName("/team-members")}
        >
          <Users className="h-4 w-4" />
          Members
        </NavLink>
      </Flex>

      {/* Other */}
      <Flex direction="column" mb="4">
        <TypographyH3 className="mb-2 text-xs font-semibold uppercase text-muted-foreground">
          Other
        </TypographyH3>
        <NavLink
          to="#"
          onClick={() => setSupportDialogOpen(true)}
          className={getLinkClassName("/undefined-link")}
        >
          <MessageCircle className="h-4 w-4" />
          Support
        </NavLink>
      </Flex>

      <Dialog open={supportDialogOpen} onOpenChange={setSupportDialogOpen}>
        <DialogContent>
          <TypographyH3>Get in touch</TypographyH3>

          <Flex gap="12px" align="center">
            <MailIcon className="h-8 w-8" />
            <Flex direction="column" gap="4">
              <TypographyH4>Email us</TypographyH4>
              <TypographyP className="pt-0">
                support@getmarketerai.com
              </TypographyP>
            </Flex>
          </Flex>
        </DialogContent>
      </Dialog>
    </>
  );
};
