import { io, Socket } from "socket.io-client";

class SocketService {
  socket: Socket | null = null;

  connect({ token }: { token: string }) {
    if (this.socket?.connected) return;

    this.socket = io(`${import.meta.env.VITE_WS_URL}`, {
      transports: ["websocket"],
      auth: { token },
    });
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  on<T>(event: string, callback: (data: T) => void) {
    if (this.socket) {
      this.socket.on(event, callback);
    }
  }

  emit<T>(event: string, data: T) {
    if (this.socket) {
      this.socket.emit(event, data);
    }
  }
}

export default new SocketService();
