import { OnboardingDto } from "@/dtos/onboarding";
import { useGetOnboardingStatusQuery } from "@/redux";
import React, { createContext } from "react";

export interface OnboardingContextType {
  onboarding?: OnboardingDto | null;
  reload: () => void;
}

export const OnboardingContext = createContext<
  OnboardingContextType | undefined
>({
  onboarding: undefined,
  reload: () => {},
});

export interface OnboardingProviderProps {
  children: React.ReactNode;
}

export const OnboardingProvider = ({ children }: OnboardingProviderProps) => {
  const { data: onboardingResponse, refetch: reload } =
    useGetOnboardingStatusQuery();

  const value = {
    onboarding: onboardingResponse,
    reload,
  };

  return (
    <OnboardingContext.Provider value={value}>
      {children}
    </OnboardingContext.Provider>
  );
};
