import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Editor } from "@tiptap/react";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { ImageIcon } from "lucide-react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useState } from "react";

type AddImageModalProps = {
  editor: Editor | null;
};

const AddImageModal = ({ editor }: AddImageModalProps) => {
  const [open, setOpen] = useState(false);

  const imageURLFormSchema = z.object({
    img_url: z.string().url(),
    alt_text: z.string().max(100).optional(),
    img_title: z.string().max(100).optional(),
  });

  const imageURLForm = useForm<z.infer<typeof imageURLFormSchema>>({
    resolver: zodResolver(imageURLFormSchema),
    defaultValues: {
      img_url: "",
      alt_text: "",
      img_title: "",
    },
  });

  const setImageURl = (values: z.infer<typeof imageURLFormSchema>) => {
    editor
      ?.chain()
      .setImage({
        src: values.img_url,
        alt: values.alt_text ? values.alt_text : "",
        title: values.img_title ? values.img_title : "",
      })
      .focus()
      .run();
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button variant="ghost" className="h-full w-[40px] px-0">
          <ImageIcon size={16} />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Please Select an Image Source</DialogTitle>
        </DialogHeader>
        <DialogDescription className="flex flex-col space-y-4">
          <Form {...imageURLForm}>
            <form
              onSubmit={imageURLForm.handleSubmit(setImageURl)}
              className="space-y-4"
            >
              <FormField
                control={imageURLForm.control}
                name="img_url"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Image URL</FormLabel>
                    <FormControl>
                      <Input placeholder="https://..." {...field} />
                    </FormControl>
                    <FormDescription>
                      This is the URL of the image you wish to use
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={imageURLForm.control}
                name="alt_text"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Alternate Text Description</FormLabel>
                    <FormControl>
                      <Input placeholder="An image containing..." {...field} />
                    </FormControl>
                    <FormDescription>
                      The alternate text description for your image
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={imageURLForm.control}
                name="img_title"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Title of the Image</FormLabel>
                    <FormControl>
                      <Input placeholder="An image of..." {...field} />
                    </FormControl>
                    <FormDescription>The title of your image</FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type="submit">Add Image</Button>
            </form>
          </Form>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
};

export default AddImageModal;
