import { NotificationDto } from "@/dtos";
import { baseApi } from "./base-api.slice";

const baseApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ["Notification"],
});

const notificationsApi = baseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<{ notifications: NotificationDto[] }, void>(
      {
        query: () => "notifications",
        providesTags: ["Notification"],
      }
    ),
  }),
});

export const { useGetNotificationsQuery } = notificationsApi;

export const { invalidateTags: invalidateNotificationsTags } =
  notificationsApi.util;
