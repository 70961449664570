import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import axios from "axios";
import { baseApi } from "./base-api.slice";
import { decodeJwt } from "jose";

export const hasTokenExpired = (token: string): boolean => {
  if (!token) return true;
  const decodedToken = decodeJwt(token);
  if (!decodedToken?.exp) return true;
  return decodedToken.exp < Date.now() / 1000;
};

// New utility function for logout operations
const clearAllStorageData = () => {
  localStorage.removeItem("token");
  sessionStorage.clear();
  document.cookie.split(";").forEach((c) => {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
  if (window.indexedDB) {
    window.indexedDB.databases().then((dbs) => {
      dbs.forEach((db) => {
        if (db.name) {
          window.indexedDB.deleteDatabase(db.name);
        }
      });
    });
  }
};

interface AuthState {
  token?: string;
  error?: string;
  user?: {
    id: string;
    email: string;
    createdAt: string;
    updatedAt: string;
  };
}

const initialState: AuthState = {
  token: (() => {
    const storedToken = localStorage.getItem("token");
    return storedToken && !hasTokenExpired(storedToken)
      ? storedToken
      : undefined;
  })(),
  error: undefined,
  user: undefined,
};

export const setLoginData = createAsyncThunk(
  "auth/setLoginData",
  async (token: string, { dispatch }) => {
    dispatch(setToken(token));
    dispatch(fetchUserData(token));
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { dispatch }) => {
    dispatch(baseApi.util.resetApiState());
    dispatch(setToken(undefined));
    clearAllStorageData();
  }
);

export const checkTokenExpiry = createAsyncThunk(
  "auth/checkTokenExpiry",
  async (_, { getState, dispatch }) => {
    const { token } = (getState() as RootState).auth;
    if (token && hasTokenExpired(token)) {
      dispatch(logout());
    }
  }
);

export const fetchUserData = createAsyncThunk(
  "auth/fetchUserData",
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_API_URL}/auth/whoami`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(new Error("Error fetching user data."));
    }
  }
);

export const initializeAuth = createAsyncThunk(
  "auth/initializeAuth",
  async (_, { dispatch }) => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(setToken(token));
      await dispatch(fetchUserData(token));
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | undefined>) => {
      state.token = action.payload;
      // Add token to local storage for legacy hooks. We can remove this once they havae
      // all been migrated
      if (action.payload) {
        localStorage.setItem("token", action.payload);
      }
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.user = undefined;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(fetchUserData.rejected, (state) => {
        state.user = undefined;
        state.error = "Error fetching user data.";
      })
      .addCase(logout.fulfilled, (state) => {
        state.token = undefined;
        state.user = undefined;
        state.error = undefined;
      })
      .addCase(initializeAuth.fulfilled, () => {
        // No state changes needed here, just ensuring the action is handled
      });
  },
});

export const { setToken, setError } = authSlice.actions;
export const selectToken = (state: RootState) => state.auth.token;
export const selectError = (state: RootState) => state.auth.error;
export const selectUser = (state: RootState) => state.auth.user;
export default authSlice.reducer;
