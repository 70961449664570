import { ApiKeyForm } from "@/components/forms";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { useState } from "react";
import { Flex } from "@radix-ui/themes";
import { useApiKeys, useBrandContext } from "@/hooks";
import { Ellipsis } from "lucide-react";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { formatDate } from "date-fns";
import axios from "axios";
import { useAuth } from "@/hooks/use-auth";
import { useGetBrandsQuery } from "@/redux";

export const ApiKeys = (): JSX.Element => {
  const { token } = useAuth();

  const { brand } = useBrandContext();

  const [error, setError] = useState<Error | null>(null);

  const [dialogOpen, setDialogOpen] = useState(false);

  const { error: brandsLoadingError } = useGetBrandsQuery();

  const {
    response: apiKeys,
    reload: reloadApiKeys,
    error: apiKeysLoadingError,
  } = useApiKeys({
    brandId: brand?.id,
  });

  const deactivateApiKey = async ({ apiKeyId }: { apiKeyId: string }) => {
    try {
      await axios.post(
        `${import.meta.env.VITE_API_URL}/brands/${
          brand?.id
        }/api-keys/${apiKeyId}/deactivate`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      reloadApiKeys();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError(new Error(error.response?.statusText));
      } else {
        setError(new Error("Something went wrong. Please try again later."));
      }
    }
  };

  const deleteApiKey = async ({ apiKeyId }: { apiKeyId: string }) => {
    try {
      await axios.delete(
        `${import.meta.env.VITE_API_URL}/brands/${
          brand?.id
        }/api-keys/${apiKeyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      reloadApiKeys();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError(new Error(error.response?.statusText));
      } else {
        setError(new Error("Something went wrong. Please try again later."));
      }
    }
  };

  return (
    <>
      <Flex justify="between" pb="3">
        <h1 className="text-lg font-semibold md:text-2xl">API Keys</h1>
      </Flex>

      {(error || brandsLoadingError || apiKeysLoadingError) && (
        <Flex pb="3">
          <Alert variant="destructive">
            <ExclamationTriangleIcon className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>
              {error?.message ||
                (brandsLoadingError && "error" in brandsLoadingError
                  ? brandsLoadingError.error
                  : "Failed to load brands") ||
                (apiKeysLoadingError &&
                typeof apiKeysLoadingError === "object" &&
                "error" in apiKeysLoadingError
                  ? String(apiKeysLoadingError.error)
                  : "Failed to load API keys")}
            </AlertDescription>
          </Alert>
        </Flex>
      )}

      <Flex className="rounded-lg border border-dashed shadow-sm h-[95%]">
        {(!apiKeys || apiKeys?.apiKeys?.length === 0) && (
          <div className="flex flex-1 flex-col items-center justify-center gap-1 text-center">
            <h3 className="text-2xl font-bold tracking-tight">
              You have no API keys
            </h3>
            <p className="text-sm text-muted-foreground">
              Click the button below to create an API key.
            </p>
            <Flex>
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger>
                    <Button
                      className={`mt-4 ${
                        brand === null ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                      onClick={() => setDialogOpen(true)}
                    >
                      Create API Key
                    </Button>
                  </TooltipTrigger>
                  {brand === null && (
                    <TooltipContent>
                      <p>You must select a brand to create an API key</p>
                    </TooltipContent>
                  )}
                </Tooltip>
              </TooltipProvider>
            </Flex>
          </div>
        )}

        {apiKeys && apiKeys?.apiKeys?.length > 0 && (
          <Flex flexGrow="1" p="5" direction="column">
            <Flex>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Name</TableHead>
                    <TableHead>Status</TableHead>
                    <TableHead>Created At</TableHead>
                    <TableHead>Actions</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {apiKeys.apiKeys.map((apiKey) => (
                    <TableRow key={apiKey?.id}>
                      <TableCell className="font-medium">
                        {apiKey.name}
                      </TableCell>
                      <TableCell>
                        {apiKey.active ? "Active" : "Inactive"}
                      </TableCell>
                      <TableCell>
                        {formatDate(apiKey.createdAt, "PPPp")}
                      </TableCell>
                      <TableCell>
                        <DropdownMenu>
                          <DropdownMenuTrigger>
                            <Button variant="ghost">
                              <Ellipsis />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent>
                            <DropdownMenuItem
                              onClick={() =>
                                deactivateApiKey({ apiKeyId: apiKey.id })
                              }
                            >
                              Deactivate
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              onClick={() =>
                                deleteApiKey({ apiKeyId: apiKey.id })
                              }
                            >
                              Delete
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Flex>
            <Flex>
              <Button className="mt-4" onClick={() => setDialogOpen(true)}>
                Add API Key
              </Button>
            </Flex>
          </Flex>
        )}

        <Flex>
          <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Add API Key</DialogTitle>
              </DialogHeader>
              <ApiKeyForm
                brandId={brand?.id || ""}
                onSuccess={() => {
                  reloadApiKeys();
                }}
                onError={(error) => {
                  setError(error);
                }}
              />
            </DialogContent>
          </Dialog>
        </Flex>
      </Flex>
    </>
  );
};
