import { ColumnDef } from "@tanstack/react-table";
import { formatDistanceToNow } from "date-fns";
import { Flex } from "@radix-ui/themes";
import { Button } from "@/components/ui/button";

export type ContentPublishedTableItem = {
  id: string;
  createdAt: Date;
  brand: { id: string; name: string };
  brief: string;
  type: string;
  status: string;
  targetDate?: Date;
  latestVersion?: Date;
  location?: string;
};

export const columns: ColumnDef<ContentPublishedTableItem>[] = [
  {
    accessorKey: "brief",
    header: "Brief",
    cell: ({ row }) => {
      return <Flex align="center">{row.original.brief}</Flex>;
    },
  },
  {
    accessorKey: "type",
    header: ({ column }) => {
      return (
        <Flex align="center" justify="center">
          <Button
            variant="ghost"
            onClick={() => {
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Type
          </Button>
        </Flex>
      );
    },
    cell: ({ row }) => {
      return (
        <Flex align="center" justify="center">
          {row.original.type}
        </Flex>
      );
    },
  },
  {
    accessorKey: "targetDate",
    header: ({ column }) => {
      return (
        <Flex align="center" justify="center">
          <Button
            variant="ghost"
            onClick={() => {
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Published
          </Button>
        </Flex>
      );
    },
    cell: ({ row }) => {
      return (
        <Flex align="center" justify="center">
          {row.original.targetDate
            ? formatDistanceToNow(row.original.targetDate, {
                addSuffix: true,
              })
            : "No publish date"}
        </Flex>
      );
    },
  },
];
