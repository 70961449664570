import { LoginContainer } from "@/containers";
import { Flex } from "@radix-ui/themes";

export const LoginPage = (): JSX.Element => {
  return (
    <Flex
      width="100%"
      height="100%"
      align="center"
      justify="center"
      direction="column"
      gap="6"
    >
      <LoginContainer />
    </Flex>
  );
};
