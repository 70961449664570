import { createContext, useEffect } from "react";
import { googleLogout } from "@react-oauth/google";
import { useGoPasswordlessContext } from "@gopasswordless/sdk";
import {
  setLoginData,
  logout as reduxLogout,
  selectToken,
  selectError,
  RootState,
  selectUser,
  AppDispatch,
  checkTokenExpiry,
  setError,
} from "@/redux";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "sonner";

export interface AuthContextType {
  token?: string;
  error?: string;
  user?: {
    id: string;
    email: string;
    createdAt: string;
    updatedAt: string;
  };
  handleLogin: (token: string) => void;
  handleError: (error: string) => void;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextType | undefined>({
  token: undefined,
  error: undefined,
  user: undefined,
  handleLogin: () => {},
  handleError: () => {},
  logout: () => {},
});

export interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const token = useSelector((state: RootState) => selectToken(state));
  const error = useSelector((state: RootState) => selectError(state));
  const user = useSelector((state: RootState) => selectUser(state));

  const {
    token: gplToken,
    logout: gplLogout,
    error: gplError,
  } = useGoPasswordlessContext();

  const handleLogin = (token: string) => {
    dispatch(setLoginData(token));
  };

  const handleError = (error: string) => {
    dispatch(setError(error));
  };

  useEffect(() => {
    if (!error) return;
    toast.error(error, {
      duration: 5000,
    });
  }, [error]);

  useEffect(() => {
    if (!gplToken) return;
    dispatch(setLoginData(gplToken));
  }, [gplToken]);

  useEffect(() => {
    if (!gplError) return;
    dispatch(setError(gplError));
  }, [gplError]);

  useEffect(() => {
    dispatch(checkTokenExpiry());
  }, []);

  const logout = () => {
    dispatch(reduxLogout());
    googleLogout();
    gplLogout();
  };

  return (
    <AuthContext.Provider
      value={{ token, error, user, handleLogin, handleError, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};
