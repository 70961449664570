import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import axios from "axios";
import { useAuth } from "@/hooks/use-auth";

const brandFormSchema = z.object({
  name: z.string().min(1).max(255),
  mission: z.string().min(1).max(500),
  homepage: z.string().url(),
});

export interface BrandFormProps {
  onSuccess: (brand: {
    id: string;
    name: string;
    mission: string;
    homepage: string;
  }) => void;
  onError: (error: Error) => void;
}

export const BrandForm = ({
  onSuccess,
  onError,
}: BrandFormProps): JSX.Element => {
  const { token } = useAuth();

  const form = useForm<z.infer<typeof brandFormSchema>>({
    resolver: zodResolver(brandFormSchema),
    defaultValues: {
      name: "",
      mission: "",
      homepage: "",
    },
  });

  const onSubmit = async (values: z.infer<typeof brandFormSchema>) => {
    axios
      .post(`${import.meta.env.VITE_API_URL}/brands`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        onSuccess(data.brand);
      })
      .catch((error) => {
        onError(error);
      });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-3">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Brand Name</FormLabel>
              <FormControl>
                <Input placeholder="Marketer AI" {...field} />
              </FormControl>
              <FormDescription>This is the name of your brand.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="mission"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Mission</FormLabel>
              <FormControl>
                <Input
                  placeholder="To make marketing easy for everyone"
                  {...field}
                />
              </FormControl>
              <FormDescription>
                This is the mission of your brand.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="homepage"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Homepage</FormLabel>
              <FormControl>
                <Input placeholder="https://marketer.ai" {...field} />
              </FormControl>
              <FormDescription>
                This is the homepage of your brand, we'll use this to learn more
                about what you do so we can target the content to your audience.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button type="submit">Submit</Button>
      </form>
    </Form>
  );
};
