import { useContext } from "react";
import {
  OnboardingContext,
  OnboardingContextType,
} from "@/contexts/onboarding";

export const useOnboardingContext = (): OnboardingContextType => {
  const context = useContext(OnboardingContext);
  if (context === undefined) {
    throw new Error("useOnboarding must be used within a OnboardingProvider");
  }
  return context;
};
