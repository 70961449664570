import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

export const BrandSelection = ({
  brands,
  disabled,
  value,
  onSelect,
}: {
  brands: { id: string; name: string }[];
  disabled?: boolean;
  value?: string;
  onSelect: (brandId: string) => void;
}): JSX.Element => {
  return (
    <Select disabled={disabled} value={value} onValueChange={onSelect}>
      <SelectTrigger style={{ maxWidth: "69%" }}>
        <SelectValue placeholder="Brand" />
      </SelectTrigger>
      <SelectContent>
        {brands.map((brand) => (
          <SelectItem key={brand.id} value={brand.id}>
            {brand.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
