import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Skeleton } from "@/components/ui/skeleton";
import { Flex } from "@radix-ui/themes";
import { RingLoader } from "react-spinners";

export const ContentResearchingComponent = (): JSX.Element => {
  return (
    <Flex pt="2" pb="7" direction="column" gap="4">
      <Alert>
        <Flex justify="start" align="center" gap="4">
          <RingLoader size={25} />
          <Flex direction="column">
            <AlertTitle>Heads up!</AlertTitle>
            <AlertDescription>
              We're currently researching this content. The results of this
              research will then be used to generate a first draft. Please check
              back in a few minutes.
            </AlertDescription>
          </Flex>
        </Flex>
      </Alert>

      <Flex justify="between" mb="5">
        <Skeleton className="w-[75%] h-12" />
      </Flex>

      <Flex direction="column" pt="3" pb="9">
        {[...Array(1)].map((_, index) => (
          <Skeleton key={`heading-${index}`} className="w-[50%] h-8 mb-1" />
        ))}
        {[...Array(5)].map((_, index) => (
          <Skeleton key={`paragraph-${index}`} className="w-full h-4 mb-1" />
        ))}
        <Skeleton className="w-[30%] h-4 mb-1" />
        <br />
        {[...Array(1)].map((_, index) => (
          <Skeleton key={`heading-${index}`} className="w-[30%] h-8 mb-1" />
        ))}
        {[...Array(5)].map((_, index) => (
          <Skeleton key={`paragraph-${index}`} className="w-full h-4 mb-1" />
        ))}
        <Skeleton className="w-[60%] h-4 mb-1" />
        <br />
        {[...Array(1)].map((_, index) => (
          <Skeleton key={`heading-${index}`} className="w-[30%] h-8 mb-1" />
        ))}
        {[...Array(5)].map((_, index) => (
          <Skeleton key={`paragraph-${index}`} className="w-full h-4 mb-1" />
        ))}
        <Skeleton className="w-[10%] h-4 mb-1" />
      </Flex>
    </Flex>
  );
};
