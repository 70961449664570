import { Flex } from "@radix-ui/themes";
import { TypographyH3 } from "../typography";
import { Button } from "../ui/button";
import { Download } from "lucide-react";
import { Skeleton } from "../ui/skeleton";

export interface BlogThumbnailProps {
  thumbnail: string;
  generating: boolean;
  failed: boolean;
}

export const BlogThumbnail = ({
  thumbnail,
  generating,
  failed,
}: BlogThumbnailProps): JSX.Element => {
  return (
    <Flex className="border rounded-lg" p="4" direction="column" gap="2">
      <TypographyH3>Thumbnail</TypographyH3>
      {failed && (
        <img
          src="https://getmarketerai.com/placeholder.svg"
          style={{ maxHeight: "500px" }}
        />
      )}
      {!failed && generating && <Skeleton className="w-[100%] h-[500px]" />}
      {!failed && !generating && thumbnail && (
        <Flex direction="column" width="100%" gap="12px">
          <img src={thumbnail} alt="Thumbnail" width="100%" />
          <a href={thumbnail} target="_blank" style={{ width: "100%" }}>
            <Button style={{ width: "100%" }}>
              <Flex gap="2" align="center">
                <Download size={18} />
                Download Thumbnail
              </Flex>
            </Button>
          </a>
        </Flex>
      )}
      {!failed && !generating && !thumbnail && (
        <Flex direction="column" width="100%" gap="12px">
          <img
            src="https://getmarketerai.com/placeholder.svg"
            alt="Marketer AI Placeholder"
          />
          <a href={thumbnail} target="_blank" style={{ width: "100%" }}>
            <Button style={{ width: "100%" }}>
              <Flex gap="2" align="center">
                <Download size={18} />
                Download Thumbnail
              </Flex>
            </Button>
          </a>
        </Flex>
      )}
    </Flex>
  );
};
