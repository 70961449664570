"use client";

import {
  ColumnDef,
  PaginationState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "../ui/button";
import { useEffect, useState } from "react";
import { Flex } from "@radix-ui/themes";
import { DataTableV2Pagination } from "./datatable-v2-pagination.component";
import { useNavigate } from "react-router-dom";

interface DataTableV2Props<TData extends object, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  pageSize?: number;
  actions?: Array<{ name: string; action: () => void }>;
}

export function DataTableV2<TData extends object, TValue>({
  columns,
  data,
  pageSize,
  actions,
}: DataTableV2Props<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const navigate = useNavigate();

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      pagination,
    },
  });

  useEffect(() => {
    if (!pageSize) return;
    table.setPageSize(pageSize);
  }, [table, pageSize]);

  return (
    <>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  onSelect={() => row.toggleSelected()}
                  onClick={() => {
                    if (
                      "location" in row.original &&
                      typeof row.original.location === "string"
                    ) {
                      navigate(row.original.location);
                    }
                  }}
                  style={{
                    cursor: "location" in row.original ? "pointer" : "auto",
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <Flex justify="between" gap="40px">
        <Flex gap="12px">
          {actions &&
            actions.map((action, index) => (
              <Button key={index} onClick={action.action}>
                {action.name}
              </Button>
            ))}
        </Flex>

        <DataTableV2Pagination
          pagination={pagination}
          pageCount={table.getPageCount()}
          onPageSizeChange={(pageSize) =>
            setPagination((prev) => ({ ...prev, pageSize }))
          }
          onNextPage={() =>
            setPagination((prev) => ({
              ...prev,
              pageIndex: prev.pageIndex + 1,
            }))
          }
          onPreviousPage={() =>
            setPagination((prev) => ({
              ...prev,
              pageIndex: prev.pageIndex - 1,
            }))
          }
          onFirstPage={() =>
            setPagination((prev) => ({ ...prev, pageIndex: 0 }))
          }
          onLastPage={() =>
            setPagination((prev) => ({
              ...prev,
              pageIndex: table.getPageCount() - 1,
            }))
          }
        />
      </Flex>
    </>
  );
}
