import { ColumnDef } from "@tanstack/react-table";
import { formatDate, formatDistanceToNow } from "date-fns";
import { Flex } from "@radix-ui/themes";
import { CalendarCheck, Pencil, X } from "lucide-react";
import { RingLoader } from "react-spinners";
import { Button } from "@/components/ui/button";

export type ContentDraftsTableItem = {
  id: string;
  createdAt: Date;
  brand: { id: string; name: string };
  brief: string;
  type: string;
  status: string;
  targetDate?: Date;
  latestVersion?: Date;
  location?: string;
  isApproved?: boolean;
};

export const columns: ColumnDef<ContentDraftsTableItem>[] = [
  {
    accessorKey: "brief",
    header: "Brief",
    cell: ({ row }) => {
      return <Flex align="center">{row.original.brief}</Flex>;
    },
  },
  {
    accessorKey: "type",
    header: ({ column }) => {
      return (
        <Flex align="center" justify="center">
          <Button
            variant="ghost"
            onClick={() => {
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Type
          </Button>
        </Flex>
      );
    },
    cell: ({ row }) => {
      return (
        <Flex align="center" justify="center">
          {row.original.type}
        </Flex>
      );
    },
  },
  {
    accessorKey: "status",
    header: ({ column }) => {
      return (
        <Flex align="center" justify="center">
          <Button
            variant="ghost"
            onClick={() => {
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Status
          </Button>
        </Flex>
      );
    },
    cell: ({ row }) => {
      return (
        <Flex align="center" justify="center">
          {row.original.status === "draft" && !row.original.isApproved && (
            <Flex gap="10px" align="center">
              <Pencil size={25} /> Draft
            </Flex>
          )}
          {row.original.status === "draft" && row.original.isApproved && (
            <Flex gap="10px" align="center">
              <CalendarCheck size={25} /> Approved
            </Flex>
          )}
          {row.original.status === "researching" && (
            <Flex gap="10px" align="center">
              <RingLoader size={25} /> Researching
            </Flex>
          )}
          {row.original.status === "generating" && (
            <Flex gap="10px" align="center">
              <RingLoader size={25} /> Generating
            </Flex>
          )}
          {row.original.status === "generating_images" && (
            <Flex gap="10px" align="center">
              <RingLoader size={24} /> Generating
            </Flex>
          )}
          {row.original.status === "images_failed" && (
            <Flex gap="10px" align="center">
              <X /> Error
            </Flex>
          )}
          {row.original.status === "failed" && (
            <Flex gap="10px" align="center">
              <X /> Error
            </Flex>
          )}
        </Flex>
      );
    },
  },
  {
    accessorKey: "targetDate",
    header: ({ column }) => {
      return (
        <Flex align="center" justify="center">
          <Button
            variant="ghost"
            onClick={() => {
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Publish Date
          </Button>
        </Flex>
      );
    },
    cell: ({ row }) => {
      return (
        <Flex align="center" justify="center">
          {row.original.targetDate
            ? formatDate(row.original.targetDate, "PPP")
            : "No publish date"}
        </Flex>
      );
    },
  },
  {
    accessorKey: "latestVersion",
    header: ({ column }) => {
      return (
        <Flex align="center" justify="center">
          <Button
            variant="ghost"
            onClick={() => {
              column.toggleSorting(column.getIsSorted() === "asc");
            }}
          >
            Last Updated
          </Button>
        </Flex>
      );
    },
    cell: ({ row }) => {
      return (
        <Flex align="center" justify="center">
          {row.original.latestVersion
            ? formatDistanceToNow(row.original.latestVersion, {
                addSuffix: true,
              })
            : "No versions yet"}
        </Flex>
      );
    },
  },
];
