import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import authSlice from "./slices/auth.slice";
import { baseApi, tokenExpirationMiddleware } from "./slices/base-api.slice";
import editorSlice from "./slices/editor.slice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    editor: editorSlice,
    [baseApi.reducerPath]: baseApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      baseApi.middleware,
      tokenExpirationMiddleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
