import { useSkipOnboardingMutation } from "@/redux";

export const OnboardingSkipContainer = (): JSX.Element => {
  const [skipOnboarding] = useSkipOnboardingMutation();

  const handle = () => {
    skipOnboarding();
  };

  return (
    <p style={{ cursor: "pointer" }} onClick={handle}>
      Skip
    </p>
  );
};
