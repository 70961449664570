import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import axios from "axios";
import { useState } from "react";
import { CheckIcon, CopyIcon } from "lucide-react";
import { Label } from "@/components/ui/label";
import { Flex } from "@radix-ui/themes";
import { TypographyP } from "@/components/typography";
import { useAuth } from "@/hooks/use-auth";

const apiKeyFormSchema = z.object({
  name: z.string().min(1).max(255),
});

export interface ApiKeyFormProps {
  brandId: string;
  onSuccess: (apiKey: { id: string; name: string }) => void;
  onError: (error: Error) => void;
}

export const ApiKeyForm = ({
  brandId,
  onSuccess,
  onError,
}: ApiKeyFormProps): JSX.Element => {
  const { token } = useAuth();

  const [apiKey, setApiKey] = useState<string | null>(null);

  const [copied, setCopied] = useState(false);

  const form = useForm<z.infer<typeof apiKeyFormSchema>>({
    resolver: zodResolver(apiKeyFormSchema),
    defaultValues: {
      name: "",
    },
  });

  const copyApiKey = () => {
    navigator.clipboard.writeText(apiKey || "");
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const onSubmit = async (values: z.infer<typeof apiKeyFormSchema>) => {
    axios
      .post(
        `${import.meta.env.VITE_API_URL}/brands/${brandId}/api-keys`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(({ data }) => {
        onSuccess(data.apiKey);
        setApiKey(data.token);
      })
      .catch((error) => {
        onError(error);
      });
  };

  return (
    <>
      {apiKey && (
        <Flex direction="column">
          <TypographyP className="pb-4">
            Here is your API key. Please copy it now and make sure to store it
            securely. For security reasons we are not able to show the API key
            again.
          </TypographyP>
          <div className="flex items-center space-x-2">
            <div className="grid flex-1 gap-2">
              <Label htmlFor="link" className="sr-only">
                Link
              </Label>
              <Input id="link" disabled defaultValue={apiKey} readOnly />
            </div>
            <Button
              type="submit"
              size="sm"
              className="px-3"
              disabled={copied}
              onClick={copyApiKey}
            >
              {copied ? (
                <>
                  <span className="sr-only">Copied</span>
                  <CheckIcon className="h-4 w-4" />
                </>
              ) : (
                <>
                  <span className="sr-only">Copy</span>
                  <CopyIcon className="h-4 w-4" />
                </>
              )}
            </Button>
          </div>
        </Flex>
      )}
      {!apiKey && (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-3">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>API Key Name</FormLabel>
                  <FormControl>
                    <Input placeholder="NextJS Blog..." {...field} />
                  </FormControl>
                  <FormDescription>The name of the API key</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button type="submit">Submit</Button>
          </form>
        </Form>
      )}
    </>
  );
};
