import Schedule from "@/components/ui/schedule";
import { useBrandContext } from "@/hooks";
import { useGetContentQuery } from "@/redux";
import { Flex } from "@radix-ui/themes";
import {
  Brain,
  CalendarCheck,
  CalendarClock,
  Hourglass,
  Lightbulb,
  Pencil,
} from "lucide-react";

export const ContentSchedule = (): JSX.Element => {
  const { brand } = useBrandContext();
  const { data } = useGetContentQuery(
    {
      brandId: brand?.id || "",
    },
    {
      skip: !brand,
      pollingInterval: 10000,
    }
  );

  const getIcon = ({
    status,
    isApproved,
    targetDate,
  }: {
    status: string;
    isApproved: boolean;
    targetDate?: string;
  }) => {
    if (status === "idea") {
      return <Lightbulb size={64} />;
    }
    if (status === "researching") {
      return <Brain size={64} />;
    }
    if (status.includes("generating")) {
      return <Hourglass size={64} />;
    }
    if (status === "draft") {
      if (isApproved && targetDate && new Date(targetDate) < new Date()) {
        return <CalendarClock size={64} />;
      } else {
        return <Pencil size={64} />;
      }
    }
    if (status === "final") {
      return <CalendarCheck size={64} />;
    }

    return <Lightbulb size={64} />;
  };

  return (
    <Schedule
      events={
        data?.contents
          .filter((content) => content.targetDate)
          .map((content) => ({
            id: content.id,
            content: (
              <Flex
                align="center"
                justify="center"
                gap="4"
                py="2"
                key={content.id}
              >
                {getIcon({
                  status: content.status,
                  isApproved: content.isApproved,
                  targetDate: content.targetDate?.toString(),
                })}
                {content.brief.length > 30
                  ? `${content.brief.slice(0, 30)}...`
                  : content.brief}
              </Flex>
            ),
            date: content.targetDate!,
            color: "#0000FF",
            link: `/brands/${brand?.id}/content/${content.id}`,
          })) || []
      }
    />
  );
};
