import { ContentCardComponent, TypographyH2, TypographyP } from "@/components";
import { Skeleton } from "@/components/ui/skeleton";
import { ContentDto } from "@/dtos";
import { useOnboardingContext } from "@/hooks";
import { Flex } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RingLoader } from "react-spinners";

declare global {
  interface Window {
    plausible: (eventName: string) => void;
  }
}

export const OnboardingSuccessPage = (): JSX.Element => {
  const { reload, onboarding } = useOnboardingContext();
  const [pollInterval, setPollInterval] = useState<NodeJS.Timeout | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (onboarding?.status !== "COMPLETED") {
      const interval = setInterval(() => {
        reload();
      }, 5000);

      setPollInterval(interval);
    }
  }, [reload, onboarding]);

  useEffect(() => {
    if (onboarding?.status === "COMPLETED" && pollInterval) {
      clearInterval(pollInterval);
    }
  }, [onboarding, pollInterval]);

  const getIcon = (content: ContentDto): string => {
    switch (content.type) {
      case "blog_post":
        return "/blog.png";
      case "x_post":
        return "/x.png";
      case "linkedin_post":
        return "/linkedin.png";
      default:
        return "/blog.png";
    }
  };

  const isContentGenerating = onboarding?.status === "GENERATING_CONTENT";
  const isContentSucceeded = onboarding?.status === "COMPLETED";

  return (
    <Flex direction="column" gap="24px" width="100%">
      <Flex direction="column" gap="24px" width="100%">
        {isContentGenerating && (
          <>
            <TypographyH2 className="border-none pb-0 mb-0">
              We're generating your first content
            </TypographyH2>
            <TypographyP className="pt-0">
              Our AI is currently writing your content and crafting your images.
              Hold tight!
            </TypographyP>
            <Flex
              direction="row"
              gap="24px"
              width="100%"
              justify="between"
              align="center"
            >
              {[...Array(3)].map((_, index) => (
                <Flex key={index} direction="column" width="30%" height="200px">
                  <Skeleton className="h-full flex justify-center items-center">
                    <RingLoader color="#000" size={50} />
                  </Skeleton>
                </Flex>
              ))}
            </Flex>
            <Flex width="100%" justify="center"></Flex>
          </>
        )}
        {isContentSucceeded && (
          <>
            <TypographyH2 className="border-none pb-0 mb-0">
              Your content is ready!
            </TypographyH2>
            <TypographyP className="pt-0">
              Your content is ready! Click on a piece of content below to check
              it out.
            </TypographyP>
            <Flex direction="row" gap="24px" width="100%">
              {onboarding?.content
                ?.filter((content: ContentDto) => content.status === "draft")
                .map((content: ContentDto) => (
                  <Flex
                    direction="column"
                    key={content.id}
                    justify="between"
                    align="center"
                    width="30%"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      window.plausible("onboarding-content-clicked");
                      navigate(
                        `/brands/${onboarding.brand.id}/content/${content.id}`
                      );
                    }}
                  >
                    <ContentCardComponent
                      icon={getIcon(content)}
                      brief={content.brief}
                      selected={false}
                    />
                  </Flex>
                ))}
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  );
};
