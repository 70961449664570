import { TypographyH4, TypographyP } from "@/components";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { Skeleton } from "@/components/ui/skeleton";
import { useBrandContext } from "@/hooks";
import { useAuth } from "@/hooks/use-auth";
import {
  invalidateBrandsTags,
  useGetBrandByIdQuery,
  useGetBrandSubscriptionByBrandIdQuery,
} from "@/redux";
import {
  CheckoutEventNames,
  initializePaddle,
  Paddle,
} from "@paddle/paddle-js";
import { Subscription } from "@paddle/paddle-node-sdk";
import { Flex } from "@radix-ui/themes";
import { decodeJwt } from "jose";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export const BillingContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const { token } = useAuth();
  // Create a local state to store Paddle instance
  const [paddle, setPaddle] = useState<Paddle>();
  const { brand } = useBrandContext();
  const { data, isFetching } = useGetBrandByIdQuery(
    { id: brand?.id || "" },
    { skip: !brand }
  );
  const { data: subscription } = useGetBrandSubscriptionByBrandIdQuery(
    { brandId: brand?.id || "" },
    { skip: !brand }
  );

  // Download and initialize Paddle instance from CDN
  useEffect(() => {
    initializePaddle({
      environment: import.meta.env.VITE_PADDLE_ENVIRONMENT || "sandbox",
      token: import.meta.env.VITE_PADDLE_TOKEN,
      eventCallback: (event) => {
        if (event.name === CheckoutEventNames.CHECKOUT_COMPLETED) {
          // Wait for 5 seconds for the event to be processed by the backend, then
          // invalidate the brand cache to refresh the subscription status
          setTimeout(() => {
            dispatch(invalidateBrandsTags(["Brand"]));
          }, 5000);
        }
      },
    }).then((paddleInstance: Paddle | undefined) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  }, []);

  const openCheckout = () => {
    const decodedToken = decodeJwt(token || "") as {
      email?: string;
      username?: string;
    };

    paddle?.Checkout.open({
      items: [{ priceId: import.meta.env.VITE_PADDLE_PRICE_ID, quantity: 1 }],
      customer: {
        // GPL tokens store the email in the username field, Google tokens store the email in the email field,
        // so if email is not found, use the username field
        email: decodedToken.email || decodedToken.username || "",
      },
      customData: {
        brandId: brand?.id || "",
      },
    });
  };

  const formatPrice = ({
    subscription,
  }: {
    subscription: Partial<Subscription>;
  }): string => {
    const amount = parseInt(
      subscription?.items?.[0].price?.unitPrice.amount || "0"
    );
    const quantity = subscription?.items?.[0].price?.quantity?.minimum || 0;
    const total = (amount * quantity) / 100;
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(total);
  };

  if (data?.brand.subscriptionStatus !== "active") {
    return (
      <Flex width="100%" direction="column" gap="2">
        <TypographyP className="pt-0">
          You don't have an active subscription. You can generate up to three
          peices of content for free, upgrade if you'd like to generate more.
        </TypographyP>
        <Flex>
          <Button onClick={openCheckout}>Upgrade</Button>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex width="100%">
      {isFetching ? (
        <Skeleton className="h-6 w-full" />
      ) : (
        <Flex direction="column" width="100%" gap="4">
          <Flex>
            <TypographyP>
              You are currently subscribed to the{" "}
              <strong>
                {subscription?.subscription && subscription.subscription.items
                  ? subscription?.subscription?.items[0].price?.name
                  : "Pro"}
              </strong>{" "}
              plan.
            </TypographyP>
          </Flex>

          <Flex gap="4">
            <Card className="w-[200px]">
              <CardHeader>
                <CardTitle>Current monthly bill</CardTitle>
              </CardHeader>
              <CardContent>
                {subscription?.subscription ? (
                  <TypographyP>
                    {formatPrice({
                      subscription: subscription.subscription,
                    })}
                  </TypographyP>
                ) : (
                  <TypographyP>No subscription data</TypographyP>
                )}
              </CardContent>
            </Card>

            <Card className="w-[200px]">
              <CardHeader>
                <CardTitle>Next payment due</CardTitle>
              </CardHeader>
              <CardContent>
                {subscription?.subscription ? (
                  <TypographyP>
                    {subscription?.subscription?.nextBilledAt
                      ? new Date(
                          subscription.subscription.nextBilledAt
                        ).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })
                      : "No date available"}
                  </TypographyP>
                ) : (
                  <TypographyP>No subscription data</TypographyP>
                )}
              </CardContent>
            </Card>
          </Flex>

          <Card>
            <CardHeader>
              <Flex justify="between">
                <Flex direction="column">
                  <TypographyH4>Ideas</TypographyH4>
                  <TypographyP className="text-sm">
                    Included ideas quota resets in{" "}
                    {subscription?.subscription?.nextBilledAt
                      ? `${Math.ceil(
                          (new Date(
                            subscription.subscription.nextBilledAt
                          ).getTime() -
                            new Date().getTime()) /
                            (1000 * 60 * 60 * 24)
                        )} days`
                      : "N/A"}
                  </TypographyP>
                </Flex>
                <TypographyH4>
                  <strong>{data?.brand?.ideaCount}</strong> of{" "}
                  <strong>{data?.brand?.ideaLimit}</strong> ideas generated
                </TypographyH4>
              </Flex>
            </CardHeader>

            <CardContent>
              <Progress
                value={
                  (data?.brand?.ideaCount * 100 || 0) /
                  (data?.brand?.ideaLimit || 0)
                }
              />
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <Flex justify="between">
                <Flex direction="column">
                  <TypographyH4>Words</TypographyH4>
                  <TypographyP className="text-sm">
                    Included words quota resets in{" "}
                    {subscription?.subscription?.nextBilledAt
                      ? `${Math.ceil(
                          (new Date(
                            subscription.subscription.nextBilledAt
                          ).getTime() -
                            new Date().getTime()) /
                            (1000 * 60 * 60 * 24)
                        )} days`
                      : "N/A"}
                  </TypographyP>
                </Flex>
                <TypographyH4>
                  <strong>{data?.brand?.wordCount}</strong> of{" "}
                  <strong>{data?.brand?.wordLimit}</strong> words used
                </TypographyH4>
              </Flex>
            </CardHeader>
            <CardContent>
              <Progress
                value={
                  (data?.brand?.wordCount * 100 || 0) /
                  (data?.brand?.wordLimit || 0)
                }
              />
            </CardContent>
          </Card>
        </Flex>
      )}
    </Flex>
  );
};
