import Tiptap from "@/components/editor/tiptap-editor";
import {
  AppDispatch,
  connectEditor,
  RootState,
  updateEditorContent,
} from "@/redux";
import { Flex } from "@radix-ui/themes";
import { useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import DiffMatchPatch from "diff-match-patch";
import { debounce } from "lodash";

export interface EditorContainerProps {
  contentId: string;
  key: string;
  editable?: boolean;
  enableImages?: boolean;
}

export const EditorContainer = ({
  contentId,
  key,
  editable = true,
  enableImages = true,
}: EditorContainerProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { connected, content, saving, lastSaved } = useSelector(
    (state: RootState) => state.editor
  );

  useEffect(() => {
    if (contentId === "") return;

    dispatch(connectEditor({ contentId }));
  }, [contentId, dispatch]);

  const debouncedUpdate = useMemo(
    () =>
      debounce(
        ({
          post,
          originalHtml,
          updatedHtml,
        }: {
          post: number;
          originalHtml: string;
          updatedHtml: string;
        }) => {
          const dmp = new DiffMatchPatch();
          const diff = dmp.diff_main(originalHtml, updatedHtml);
          dmp.diff_cleanupSemantic(diff);

          // Convert the diff to a unified diff format
          const diffText = dmp.patch_toText(dmp.patch_make(originalHtml, diff));

          dispatch(updateEditorContent({ contentId, diff: diffText, post }));
        },
        2000 // 2 seconds debounce
      ),
    [contentId, dispatch]
  );

  const onUpdate = useCallback(
    ({
      post,
      originalHtml,
      updatedHtml,
    }: {
      post: number;
      originalHtml: string;
      updatedHtml: string;
    }) => {
      debouncedUpdate({ post, originalHtml, updatedHtml });
    },
    [debouncedUpdate]
  );

  return (
    <Flex direction="column" gap="4">
      {content.map((c) => (
        <Tiptap
          html={c.bodyHtml}
          saving={saving}
          lastSaved={lastSaved?.find((l) => l.order === c.order)?.date ?? null}
          connected={connected}
          editable={editable}
          key={`${key}-${c.order}`}
          enableImages={enableImages}
          onUpdate={({ html }) =>
            onUpdate({
              post: c.order,
              originalHtml: c.bodyHtml,
              updatedHtml: html,
            })
          }
        />
      ))}
    </Flex>
  );
};
