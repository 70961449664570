import { Flex } from "@radix-ui/themes";
import { Card } from "@/components/ui/card";
import { TypographyP } from "@/components/typography";
import { Button } from "@/components/ui/button";

export interface IntegrationProps {
  imgSrc: string;
  imgAlt: string;
  description: string;
  enabled: boolean;
  connected: boolean;
  onConnect: () => void;
}

export const Integration = ({
  imgSrc,
  imgAlt,
  description,
  enabled = true,
  connected = false,
  onConnect,
}: IntegrationProps): JSX.Element => {
  return (
    <Card>
      <Flex p="4" direction="column" gap="4" width="400px">
        <Flex direction="column" align="center" justify="center">
          <img src={imgSrc} alt={imgAlt} width={100} className="p-2" />
        </Flex>

        <TypographyP className="pt-0">{description}</TypographyP>

        {!connected ? (
          <Button disabled={!enabled} onClick={onConnect}>
            Connect
          </Button>
        ) : (
          <Button disabled>Connected</Button>
        )}
      </Flex>
    </Card>
  );
};
