import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../use-auth";
import { useNotificationsContext } from "../use-notifications-context";

export interface UseApiProps<TParams, TData> {
  endpoint: string;
  method: "get" | "post" | "put" | "delete";
  lazy?: boolean;
  params?: TParams;
  data?: TData;
}

export interface UseApiResponse<T> {
  response: T | null;
  error: Error | null;
  loading: boolean;
  reload: () => void;
}

export function useApi<TParams, TData, TResponse>({
  endpoint,
  method,
  lazy = false,
  params,
  data,
}: UseApiProps<TParams, TData>): UseApiResponse<TResponse> {
  const { addError } = useNotificationsContext();

  const [response, setResponse] = useState<TResponse | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [reloadCounter, setReloadCounter] = useState<number>(0);

  const { token } = useAuth();
  const navigate = useNavigate();

  const reload = useCallback(() => {
    setReloadCounter((prevCounter) => prevCounter + 1);
  }, []);

  useEffect(() => {
    if (!token) return;

    // Don't auto load if lazy is true
    if (lazy && reloadCounter === 0) return;

    setLoading(true);
    axios({
      method: method,
      url: `${import.meta.env.VITE_API_URL}/${endpoint}`,
      params: params,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setResponse(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          navigate("/auth/login");
        } else {
          setError(error);
          addError({ message: error.message });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [endpoint, method, params, data, token, navigate, reloadCounter]);

  return { response, error, loading, reload };
}
