import { useEffect, useState } from "react";
import { useApi, UseApiResponse } from "../use-api";

export const useLinkedinLink = ({
  brandId,
}: {
  brandId?: string;
}): UseApiResponse<string> => {
  const [linkedinLinkResponse, setLinkedinLinkResponse] = useState<
    UseApiResponse<string>
  >({
    response: null,
    error: null,
    loading: false,
    reload: () => {},
  });

  const { response, error, loading, reload } = useApi<unknown, unknown, string>(
    {
      endpoint: `brands/${brandId}/linkedin/auth-link`,
      method: "get",
      lazy: !brandId,
    }
  );

  useEffect(() => {
    if (response !== null || error !== null) {
      setLinkedinLinkResponse({ response, error, loading, reload });
    }
  }, [response, error, loading, reload]);

  return linkedinLinkResponse;
};
