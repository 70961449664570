import { BillingContainer } from "@/containers";
import { Flex } from "@radix-ui/themes";

export const BillingPage = (): JSX.Element => {
  return (
    <Flex direction="column">
      <Flex justify="between" pb="3">
        <h1 className="text-lg font-semibold md:text-2xl">Billing</h1>
      </Flex>

      <Flex direction="column">
        <BillingContainer />
      </Flex>
    </Flex>
  );
};
