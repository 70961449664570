import { Card, CardTitle } from "@/components/ui/card";
import { Flex } from "@radix-ui/themes";

export interface ContentCardComponentProps {
  icon: string;
  brief: string;
  selected: boolean;
}

export const ContentCardComponent = ({
  icon,
  brief,
  selected,
}: ContentCardComponentProps): JSX.Element => {
  return (
    <Card
      style={{
        flexGrow: 1,
        minHeight: "200px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className={`${selected ? "border-black" : "border"}`}
    >
      <Flex align="center" justify="center" gap="24px" p="24px">
        <img src={icon} alt={brief} width="60px" />
        <CardTitle>{brief}</CardTitle>
      </Flex>
    </Card>
  );
};
